
import CoreCrudService from "../core/CoreCrudService.d";
import CoreRepository from "../core/CoreRepository.d";
import { Config } from "../types/Config";
import ConfigFirebaseRepository from "./repository/firebase/ConfigRepository";

export default class ConfigService implements CoreCrudService<Config>{
    
    repository: CoreRepository<Config> = new ConfigFirebaseRepository();

    
    create = (element: Config): Promise<Config> => {
        return this.repository.create(element);
    }

    findOne=(elementId: string) : Promise<Config> =>{
        if (this.repository.findOne) {
            return this.repository.findOne(elementId);
        } else {
            return Promise.reject(new Error('findOne method is undefined.'));
        }
    };




}
