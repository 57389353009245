export const hexToRgb = (hexColor: string, opacity: number = 1): string => {
    const aRgbHex = hexColor.slice(1).match(/.{1,2}/g);
    if (!aRgbHex || aRgbHex.length < 3) {
        throw new Error("Invalid hex color format");
    }
    const aRgb = [
        parseInt(aRgbHex[0], 16),
        parseInt(aRgbHex[1], 16),
        parseInt(aRgbHex[2], 16)
    ];
    const rgb = `rgb(${aRgb[0]}, ${aRgb[1]}, ${aRgb[2]}, ${opacity})`;
    return rgb;
};
