import { ParsedUrlQuery } from 'querystring';
/**
 * Intenta analizar una cadena JSON de forma segura.
 * @param item - El elemento a analizar.
 * @param returnNull - Indica si se debe devolver `null` en caso de error. Si es `false`, se devuelve un objeto vacío `{}` por defecto.
 * @returns El objeto resultante de analizar la cadena JSON si la operación es exitosa, o `null` si ocurre un error y `returnNull` es `true`.
 * Si `returnNull` es `false`, se devuelve un objeto vacío `{}` en caso de error.
 */
export function safeJsonParse(item: any, returnNull: boolean) {
  try {
    return JSON.parse(item);
  } catch (err) {
    if (returnNull) {
      return null;
    }
    return {};
  }
}

/**
 * Elimina las propiedades con valor `undefined` de un objeto.
 * @param obj - El objeto del cual se eliminarán las propiedades `undefined`.
 * @returns Un nuevo objeto que contiene solo las propiedades definidas (no `undefined`) del objeto original.
 */
export function removeUndefined(obj: any) {
  return Object.entries(obj).reduce((acc: any, [key, value]) => {
    if (value !== undefined) {
      acc[key] = value;
    }
    return acc;
  }, {});
}


export function objectFindNestedKey(object: object | any, keys: string) {
	try {
		let formattedKeys = keys.replace(/\./g, "?.");
		formattedKeys = formattedKeys
			.split("?.")
			.map(key => (key.includes("-") ? `["${key}"]` : key))
			.join("?.");

		return eval(`object?.${formattedKeys}`);
	} catch (err) {
		return keys;
	}
}

export function jsonToFormData(jsonData: object) {
	const formData = new FormData();

	Object.entries(jsonData).forEach(([key, value]) => {
		if (value?.name) return formData.append(key, value, value?.name);
		else return formData.append(key, value);
	});

	return formData;
}

export function makeDictFromArray(
	arrayOfObjects: any[],
	objectKeyName: string
) {
	// return arrayOfObjects?.map(item => ({...item}))
	const result: { [key: string]: any } = {};
	arrayOfObjects?.forEach(item => {
		result[item[objectKeyName]] = item;
	});
	return result;
}




/**
 * Función para parsear un objeto específico del query del router en Next.js.
 * @param query El objeto de query del router a ser parseado.
 * @param key La clave del objeto en el query a ser parseado.
 * @returns El objeto parseado o `null` si no se puede parsear.
 */
export const parseRouterQueryObject = (query: ParsedUrlQuery, key: string): any | null => {
  let parsedObject = null;

  if (query && key in query) {
    const targetObject = query[key];

    if (targetObject) {
      if (Array.isArray(targetObject)) {
        // Si el objeto es un array, toma el primer elemento y lo parsea
        parsedObject = JSON.parse(targetObject[0]);
      } else {
        // Si el objeto es un string, lo parsea directamente
        parsedObject = JSON.parse(targetObject);
      }
    }
  }

  return parsedObject;
};
