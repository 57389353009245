import { Box, Container, Grid } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import { useIsMobile } from "../../../hooks/useWindow";
import { splitArray } from "../../../libs/utils/array.utils";
import BQuoteCard from "../../landing/BQuoteCard";
import { Customer } from "../../../libs/types/Customer";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";


interface TestimonialCarouselProps {
    customers: Customer[];
}

const TestimonialCarousel: React.FC<TestimonialCarouselProps> = ({ customers }) => {
    const isMobile = useIsMobile();
    const blocks = splitArray(customers, 3);
    return (
        <Grid md={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
            <Carousel animation="slide" sx={{ width: isMobile ? '360px' : '100%', pb: 3 }}>
                {isMobile ?
                    customers.map((elem, k) => (
                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} key={k}>
                            <TestimonialCarouselItem
                                key={k}
                                customer={elem}
                            />
                        </Box>

                    ))
                    : blocks.map((block, k) => (
                        <Box sx={{ width: '100%', height: 400, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: '33px' }} key={k}>
                            {block.map((elem, i) => (
                                <TestimonialCarouselItem
                                    key={i}
                                    customer={elem}
                                />
                            ))}

                        </Box>
                    ))}
            </Carousel>
        </Grid>
    )
}

interface TestimonialCarouselItemProps {
    customer: Customer;
}

const TestimonialCarouselItem: React.FC<TestimonialCarouselItemProps> = ({ customer }) => {

    const lan = useSelector(
        (state: any) => state?.languages?.selectedLanguage
    ).language as 'es' | 'en';

    return (
        <BQuoteCard
            title={`${customer.name} ${customer.lastname}`}
            description={customer.testimonial[lan]}
            subtitle={customer.position[lan]}
            avatar={customer.avatar}
            containerStyle={{
                width: isMobile?'100%': 390,
                height: isMobile?350:330
            }}
        />
    )
}


export default TestimonialCarousel;
