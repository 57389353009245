import { Box, Fab } from '@mui/material';
import { Contact } from '../../libs/types/Contact';
import { useEffect, useState } from 'react';
import BLink from '../BLink';
import { getIconByName } from '../footer/Footer';
import { useIsMobile } from '../../hooks/useWindow';
import { motion } from 'framer-motion';

type FloatingSocialsProps = {
    contactData: Contact[],
    position?: 'left' | 'right'
}

const FloatingSocials = ({ contactData, position = 'left' }: FloatingSocialsProps) => {
    const [isFixed, setIsFixed] = useState(true);
    const isMobile = useIsMobile();

    useEffect(() => {
        const handleScroll = () => {
            const box = document.getElementById('yourBoxId');
            if (box) {
                const distanceFromBottom = document.documentElement.scrollHeight - window.innerHeight - window.scrollY;

                if (distanceFromBottom < 1300) {
                    setIsFixed(false);
                } else {
                    setIsFixed(true);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
    const positionSx = position === 'left' ? { left: 80 } : { right: 80 }
    return !isMobile ?
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 1 }}
        ><Box
            id="yourBoxId"
            sx={{
                position: isFixed ? 'fixed' : 'static',
                top: '40%',
                ...positionSx,
                display: isFixed ? 'flex' : 'none',
                flexDirection: 'column',
                gap: 2,
            }}
        >
                {/* Mapping de tus contactos */}
                {contactData.map((contact, index) =>
                    contact.isSocialMedia ? (
                        <BLink key={index} to={contact.source}>
                            <Fab color="primary" aria-label="add">
                                {getIconByName(contact.name ?? '')}
                            </Fab>
                        </BLink>
                    ) : null
                )}
            </Box> </motion.div> : <></>
}


export default FloatingSocials

