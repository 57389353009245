import { createTheme } from "@mui/material";
import { typography } from "./typography";


export const MainTheme = createTheme(
	{	
		typography: typography,
		palette: {
			primary: {
				light: "#FF9C00",
				main: "#FF9C00",
				dark: '#FFE07A',
			},
		
			secondary: {
				light: "#130748",
				main: "#130748",
				dark: '#18116C'
			},
			error: {
				light: "#BA1A1A",
				main: "#BA1A1A",
				dark: "#FFB4AB",
			},
			background: {
				default:'#f9f9f9'
			}
		},
	}
);
