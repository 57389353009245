import React from 'react'
import Footer from '../../footer/Footer';
import { useSelector } from 'react-redux';
import { Config } from '../../../libs/types/Config';
import FloatingSocials from '../../landing/FloatingSocials';


const AppFooter = () => {


    const footerOptions = useSelector(
        (state: any) => state?.menu?.footerOptions
    );
    const config: Config = useSelector(
        (state: any) => state?.config?.config
    );
    const contactData = config && config.company ? config.company?.contactData : [];
    return (
        <><Footer
            company={config?.company}
            extraPages={footerOptions}
        />
            <FloatingSocials contactData={contactData} position='right' />
        </>
    )
}

export default AppFooter
