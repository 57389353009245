import React, { CSSProperties, MouseEventHandler, useState } from 'react';
import { Link } from 'react-scroll';
import { SxProps } from '@mui/system';
import BButton, { BButtonProps } from '../../buttons/BButton';

interface TabButtonProps extends BButtonProps {
    to: string;
    smooth?: boolean;
    duration?: number;
    children: React.ReactNode;
    containerStyle?: CSSProperties;
    buttonSx?: SxProps;
    hoverColor?: string;
}

const TabButton = ({ to, smooth = true, hoverColor = "black", duration = 250, children, containerStyle, buttonSx,...props }: TabButtonProps) => {
    const [isHovered, setIsHovered] = useState(false);
    const [buttonVariant, setButtonVariant] = useState<'text' |'contained'>('text');

    const handleMouseEnter = (event: any) => {
        setIsHovered(true);
        setButtonVariant('contained')
    };

    const handleMouseLeave = (event: any) => {
        setIsHovered(false);
        setButtonVariant('text')
    };

    return (
        <Link
            to={to}
            smooth={smooth}
            duration={duration}
            style={{ color: isHovered ? hoverColor : 'white',  cursor: to ? 'pointer' : 'none', width:'100%',  ...containerStyle }}
        >
            <BButton
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                color={props.color}
                sx={{ textTransform: 'none', minWidth: '50px', borderRadius: 8, padding: 2, my: 4, ...buttonSx }}
                {...props}
                variant={buttonVariant}
            >
                {children}
            </BButton>
        </Link>
    );
};

export default TabButton;
