import { useSelector } from 'react-redux'
import { useIsMobile } from '../../../hooks/useWindow'
import AboutSection from '../../sections/aboutUs/AboutSection'
import { Config } from '../../../libs/types/Config'

const AppAboutSection = () => {

    const config: Config = useSelector(
        (state: any) => state?.config?.config
    );
    const features = config ? config?.company.features ?? [] : [];
    const metrics = config ? config?.company.metrics ?? [] : [];


    const isMobile = useIsMobile();
    return (
        <AboutSection
            features={features}
            metrics={metrics}
            titleProps={{
                color: 'secondary',
                variant: isMobile ? 'h3' : 'h2'
            }}
        />
    )
}

export default AppAboutSection
