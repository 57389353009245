import React, { ReactNode } from 'react'
import CarouselItem from './CarouselItem'
import { Box, SxProps } from '@mui/material'
import { useSelector } from 'react-redux'
import { motion } from 'framer-motion';
import { Banner } from '../../../libs/types/Banner';
import BText, { BTextProps } from '../../BText';
import LinkableButton from '../../buttons/LinkableButton';
import BDiscountCircle from '../../BDiscountCircle';
import { useGetDefaultCtaSource } from '../../../hooks/useGetDefaultCtaSource';

type BannerCarouselItemProps = {
    banner: Banner,
    outsideContainerSx?: SxProps,
    insideContainerSx?: SxProps,
    bannerProps?: SxProps,
    bannerImageOpacity?: string,
    subtitleProps?: BTextProps
    titleProps?: BTextProps,
    descriptionProps?: BTextProps,
    extraCtaComponent?: ReactNode
}

const BannerCarouselItem = ({ extraCtaComponent, banner, bannerImageOpacity, bannerProps, insideContainerSx, titleProps, subtitleProps, descriptionProps, outsideContainerSx, }: BannerCarouselItemProps) => {
    const selectedLanguage = useSelector(
        (state: any) => state?.languages?.selectedLanguage
    ).language as 'es' | 'en';
    const defaultCtaSource = useGetDefaultCtaSource();
    const tags = banner.tags?.[selectedLanguage];
    const title = banner.title?.[selectedLanguage] || '';
    const description = banner.description?.[selectedLanguage] || '';
    const ctaButtonLabel = banner.ctaButtonLabel?.[selectedLanguage] || ''
    const ctaSource = banner.ctaSource || defaultCtaSource;
    const discount = banner.discount;

    return (
        <CarouselItem
            width='100vw'
            image={banner.backgroundImage || ''}
            outsideContainerSx={outsideContainerSx}
            insideContainerSx={insideContainerSx}
            itemOpacity={bannerImageOpacity}
        >
            <Box sx={{ display: { xs: 'none', sm: 'flex' }, flexDirection: 'column', width: '50%', height: '100%', padding: 10, justifyContent: 'center', ...bannerProps }}>
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6, delay: 0.4 }}
                >
                    <BText variant="button" color="primary" {...subtitleProps}>{tags}</BText>
                </motion.div>
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6, delay: 0.6 }}
                >
                    <BText variant="h2" color="black" fontWeight={'bold'}  {...titleProps} >{title}</BText>
                </motion.div>
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6, delay: 0.8 }}
                >
                    <BText variant="body1" color="black"  {...descriptionProps} >{description}</BText>
                </motion.div>
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6, delay: 1 }}
                >
                    <LinkableButton
                        to={ctaSource}
                        color='primary'
                        style={{ borderRadius: 0, paddingInline: '10px' }}
                        linkType='external'
                        disableElevation
                    >
                        <BText variant='button' color={'white'}>
                            {ctaButtonLabel}
                        </BText>
                    </LinkableButton>
                </motion.div>
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6, delay: 1.2 }}
                >
                    {extraCtaComponent}
                </motion.div>
                {
                    discount && <BDiscountCircle discount={discount} delay={1.2} />
                }

            </Box>
            <Box sx={{ display: { xs: 'flex', sm: 'none' }, flexDirection: 'column', width: '100%', padding: 6, justifyContent: 'center', textAlign: 'left', my: 4, ...bannerProps }}>
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6, delay: 0.4 }}
                >
                    <BText variant="button" color="primary"  {...subtitleProps}>{tags}</BText>
                </motion.div>
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6, delay: 0.6 }}
                >
                    <BText variant="h4" color="black" fontWeight={'bold'}  {...titleProps} >{title}</BText>
                </motion.div>
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6, delay: 0.8 }}
                >
                    <BText variant="body2" color="black"  {...descriptionProps} >{description}</BText>
                </motion.div>
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6, delay: 1 }}
                >
                    <LinkableButton
                        to={ctaSource}
                        color='primary'
                        style={{ borderRadius: 0, paddingInline: '10px' }}
                        disableElevation
                        linkType='external'
                    >
                        <BText variant='button' color={'white'}>
                            {ctaButtonLabel}
                        </BText>
                    </LinkableButton>
                </motion.div>
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6, delay: 1.2 }}
                >
                    {extraCtaComponent}
                </motion.div>
            </Box>

        </CarouselItem>
    )
}
export default BannerCarouselItem