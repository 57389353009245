import React, { useEffect, useState } from 'react'
import { Banner } from '../../../libs/types/Banner';
import BannerService from '../../../libs/services/BannerService';
import { useSelector } from 'react-redux';
import { Service } from '../../../libs/types/Service';
import { useIsMobile } from '../../../hooks/useWindow';
import ServiceSection from '../../sections/services/ServiceSection';
import { Config } from '../../../libs/types/Config';
import SectionContainer from '../../containers/SectionContainer';


const AppServicesSection = () => {


    const config: Config = useSelector(
        (state: any) => state?.config?.config
    );
    const services = config ? config?.company.services ?? [] : [];

    return (
        <SectionContainer id='services' height={'auto'}>
            {
                services.map((service: Service, index: number) => (
                    <ServiceSection
                        service={service}
                        descPosition={index % 2 === 0 ? 'right' : 'left'}
                    />
                ))
            }
        </SectionContainer>
    )
}

export default AppServicesSection
