
import { db } from '../../../../settings/firebase';

import {
    collection,
    addDoc,
    updateDoc,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
} from "firebase/firestore";
import CoreRepository from '../../../core/CoreRepository.d';
import { Config } from '../../../types/Config';


const collectionName = "configs";


export default class ConfigFirebaseRepository implements CoreRepository<Config>{

    // find = async (filters: any): Promise<Config[]> => {
    //     const elements: Config[] = [];
    //     const res = await getDocs(collection(db, collectionName));
    //     res.forEach((doc: any) => {
    //         const element: Config = {
    //             id: doc.id,
    //             company: doc.data().company,
    //             availablePages: doc.data().availablePages,
    //             availableExtraPages: doc.data().availableExtraPages,
    //             enable: doc.data().enable,
    //         }
    //         elements.push(element);
    //     });
    //     return elements;
    // }

    create = async (element: Config): Promise<Config> => {
        const doc: any = await addDoc(collection(db, collectionName), element);
        const res: Config = {
            id: doc.id,
            company: doc.data().company,
            availablePages: doc.data().availablePages,
            enable: doc.data().enable,
        }
        return res;
    }

    findOne = async (elementId: string): Promise<Config> => {
        const _doc = await getDoc(doc(db, collectionName, elementId));
        let element:Config;
        element= {
            id: _doc.id,
            company: _doc.data()?.company,
            availablePages: _doc.data()?.availablePages,
            enable: _doc.data()?.enable,
        }
        return element;
    }


}
