
import CoreCrudService from "../core/CoreCrudService.d";
import CoreRepository from "../core/CoreRepository.d";
import { Banner } from "../types/Banner";
import BannerFirebaseRepository from "./repository/firebase/BannerRepository";

export default class BannerService implements CoreCrudService<Banner>{
    
    repository: CoreRepository<Banner> = new BannerFirebaseRepository();

    find = (filters?: any): Promise<Banner[]> => {
        if (this.repository.find) {
            return this.repository.find(filters);
        } else {
            return Promise.reject(new Error('Find method is undefined'));
        }
    }

    create = (element: Banner): Promise<Banner> => {
        return this.repository.create(element);
    }


}
