
import React from 'react'
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import { Avatar, Box, Grid, SxProps, useTheme } from '@mui/material';
import BText from '../BText';
import { useIsMobile } from '../../hooks/useWindow';

interface BQuoteCardProps {
    avatar: string;
    title?: string;
    subtitle?: string;
    description?: string;
    backgroundColor?: string;
    containerStyle?: React.CSSProperties;
    iconStyle?: React.CSSProperties;
    titleStyle?: React.CSSProperties;
    descStyle?: React.CSSProperties;
}

const BQuoteCard: React.FC<BQuoteCardProps> = ({ avatar, title, subtitle, description, backgroundColor, containerStyle, iconStyle, titleStyle, descStyle }) => {
    const isMobile = useIsMobile();
    const theme = useTheme();
    const classes = {
        quoteIcon: {
            position: 'relative',
            left: '-85%',
            top: `20px`,
            transform: 'rotateX(180deg) rotateY(180deg)',
            zIndex: 5,
            height: 30,
        },
        body: {
            backgroundColor: backgroundColor,
            display: 'flex',
            flexDirection: 'row',
            width: isMobile ? '100%' : 450,
            height: isMobile ? 310 : 300,
            border: '5px solid',
            borderColor: theme.palette.primary.dark,
            p: 2,
            borderRadius: 2,
            ...containerStyle
        } as SxProps,
        title: {
            textAlign: 'left',
            width: '100%',
            ...titleStyle
        },
        description: {
            textAlign: 'left',
            width: '100%',
            flex: 1,
            ...descStyle
        }
    };

    return (
        <Box>
            <Box sx={classes.quoteIcon}>
                <Avatar variant="circular" sx={{color:'primary'}}>
                    <FormatQuoteIcon color='secondary' sx={{ fontSize: 36 }} />
                </Avatar>
            </Box>
            <Grid container sx={classes.body}>
                <Grid item md={12} sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
                    <BText sx={classes.description} variant="body2">
                        {description}
                    </BText>
                </Grid>
                <Grid item md={12} sx={{ display: 'flex', }}>
                    <Avatar src={avatar} style={{ width: '96px', height: '96px' }} variant="circular" />
                    <Box sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
                        <BText sx={classes.title} variant="h6" color='inherit' align="center">
                            {title}
                        </BText>
                        <BText sx={classes.title} variant="subtitle2" color='primary' align="center">
                            {subtitle}
                        </BText>
                    </Box>

                </Grid>
            </Grid>
        </Box>
    )
}



export default BQuoteCard;
