import React from 'react'
import { Service } from '../../../libs/types/Service'
import SectionContainer from '../../containers/SectionContainer'
import { Box, Grid } from '@mui/material'
import BText from '../../BText'
import { useSelector } from 'react-redux'
import BImage from '../../BImage'
import LinkableButton from '../../buttons/LinkableButton'
import FeaturesBox from './FeaturesBox'
import MovingArrowForwardIcon from '../../icons/MovingArrowForwardIcon'
import { useIsMobile } from '../../../hooks/useWindow'
import BCtaButton from '../../landing/BCtaButton'

type ServiceSectionProps = {
    service: Service,
    descPosition?: 'left' | 'right'
}
const ServiceSection = ({ service, descPosition = 'right' }: ServiceSectionProps) => {
    const selectedLanguage = useSelector(
        (state: any) => state?.languages?.selectedLanguage
    ).language as 'es' | 'en';
    const isMobile = useIsMobile();

    const renderFeaturesBox = () => {
        return (
            <Grid item md={6} display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'} >
                <FeaturesBox features={service.features ?? []} sx={{ position: 'relative', top: isMobile ? '-50px' : '-100px' }} />
            </Grid>
        )
    }

    const titleSx = isMobile ? {} : { position: 'relative', top: '5px', left: '20px', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' };

    return (
        <SectionContainer id={service.code} height={'auto'}>
            <Grid container px={isMobile ? 2 : 30}>
                <Grid item xs={12} md={12} mt={isMobile ? 12 : 15}>
                    <BText variant={isMobile ? 'h4' : 'h2'} colorVariant='secondary' fontWeight={isMobile?'bold':'regular'} sx={{ ...titleSx }}>
                        {service.title[selectedLanguage]}
                    </BText>
                </Grid>
                <Grid item xs={12} md={12}>
                    <BImage
                        width={'100%'}
                        height={isMobile ? '250px' : '450px'}
                        image={service.backgroundImage ?? ''}
                        sx={{
                            boxShadow: '20px 20px 40px rgba(0, 0, 0, 0.3)'
                        }}
                        overlayImageStyle={{
                            backgroundColor: 'rgb(254,155,0,0.1)',
                        }}
                    />
                </Grid>
                <Grid item container xs={12} md={12} justifyContent='center' mx={isMobile ? 0 : 5} my={isMobile ? 2 : 7}>
                    {(descPosition === 'right' || isMobile) && renderFeaturesBox()}
                    <Grid item md={6} px={2}>
                        <BText variant='body1'>
                            {service.description && service.description[selectedLanguage]}
                        </BText>
                        <BCtaButton
                            to={service.ctaSource}
                            label={service.ctaButtonLabel && service.ctaButtonLabel[selectedLanguage]}
                        />
                    </Grid>
                    {(descPosition === 'left' && !isMobile) && renderFeaturesBox()}
                </Grid>
            </Grid>
        </SectionContainer>
    )
}

export default ServiceSection