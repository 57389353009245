import { Box, BoxProps, SxProps } from '@mui/material';
import React from 'react'

type BImageProps = {
    width: number | string,
    height: number | string,
    image: string,
    children?: React.ReactNode,
    overlayImageStyle?: SxProps,
    imageSize?: 'cover' | 'contain' | 'auto' | string;
    imageRepeat?: 'repeat' | 'no-repeat' | 'repeat-x' | 'repeat-y' | 'round' | 'space' | string;
    sx?: SxProps
} & BoxProps
const BImage = ({ width, height, image, imageSize = 'cover', sx, imageRepeat = 'no-repeat', overlayImageStyle, onMouseEnter, onMouseLeave, children }: BImageProps) => {
    return (
        <Box
            onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}
            sx={{
                width: width,
                height: height,
                backgroundImage: `url(${image})`,
                backgroundRepeat: imageRepeat,
                backgroundSize: imageSize,
                backgroundPosition: 'center',
                ...sx
            }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgb(0,0,0,0)',
                width: '100%',
                height: '100%',
                ...overlayImageStyle
            }}>
                {children}
            </Box>
        </Box>
    )
}

export default BImage
