
import { db } from '../../../../settings/firebase';

import {
    collection,
    addDoc,
    updateDoc,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    Timestamp,
} from "firebase/firestore";
import CoreRepository from '../../../core/CoreRepository.d';
import { Post } from '../../../types/Post';

const collectionName = "posts";


export default class PostFirebaseRepository implements CoreRepository<Post> {

    mapItem = (doc: any): Post => {
 
        const timestamp:Timestamp=doc.data().publicAt;
        const publicAt =new Date(timestamp.seconds * 1000);

        return {
            id: doc.id,
            title: doc.data().title,
            shortDescription: doc.data().shortDescription,
            author: doc.data().author,
            publicAt: publicAt,
            keywords: doc.data().keywords,
            coverImage: doc.data().coverImage,
            content: doc.data().content,
            slug: doc.data().slug,
        }
    }


    find = async (filters: any): Promise<Post[]> => {
        const elements: Post[] = [];
        const res = await getDocs(collection(db, collectionName));
        res.forEach((doc: any) => {
            const element: Post = this.mapItem(doc);
            elements.push(element);
        });
        return elements;
    }

    create = async (element: Post): Promise<Post> => {
        const doc: any = await addDoc(collection(db, collectionName), element);
        const res: Post = this.mapItem(doc);
        return res;
    }

    findOne = async (elementId: string = ''): Promise<Post> => {
        const _doc = await getDoc(doc(db, collectionName, elementId));
        let element: Post;
        element = this.mapItem(_doc);
        return element;
    }
}
