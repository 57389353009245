import { configureStore } from "@reduxjs/toolkit";
import languageReducer from "./slices/language.slice";
import menuReducer from "./slices/menu.slice";
import configReducer from "./slices/config.slice";

const reducers = {
	languages: languageReducer,
	menu: menuReducer,
	config: configReducer
};

export const store = configureStore({
	reducer: reducers,
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
