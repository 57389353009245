import { Divider, Fab, Grid, IconProps, Link, SxProps } from '@mui/material'
import React from 'react';
import BText from '../BText';
import { useSelector } from 'react-redux'
import InstagramIcon from '../icons/InstagramIcon'
import EmailIcon from '@mui/icons-material/Email'
import WhatsAppIcon from '../icons/WhatsAppIcon'
import FacebookIcon from '../icons/FacebookIcon'
import { Company } from '../../libs/types/Company';
import { MenuOption } from '../../libs/types/MenuOption';
import { useIsMobile } from '../../hooks/useWindow';
import { useLanguage } from '../../hooks/useLanguage';
import { replacePlaceholders } from '../../libs/utils/string.utils';
import FlexBox from '../containers/FlexBox';
import WebIcon from '@mui/icons-material/Web';
import PersonIcon from '@mui/icons-material/Person';
import ArticleIcon from '@mui/icons-material/Article';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import MobileFriendlyIcon from '@mui/icons-material/MobileFriendly';
import WebStoriesIcon from '@mui/icons-material/WebStories';
import SettingsIcon from '@mui/icons-material/Settings';
import ApiIcon from '@mui/icons-material/Api';
import BugReportIcon from '@mui/icons-material/BugReport';
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';
import AdbIcon from '@mui/icons-material/Adb';
import SpeedIcon from '@mui/icons-material/Speed';
import AppleIcon from '@mui/icons-material/Apple';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import BLink from '../BLink';
import SectionContainer from '../containers/SectionContainer';

type FooterProps = {
    company: Company
    sx?: SxProps,
    extraPages?: MenuOption[]
}

export const getIconByName = (iconName: string, iconProps: IconProps = {}) => {
    let Icon;
    switch (iconName) {
        case 'instagram':
            Icon = InstagramIcon
            break;
        case 'whatsapp':
            Icon = WhatsAppIcon
            break;
        case 'facebook':
            Icon = FacebookIcon
            break;
        case 'web':
            Icon = WebIcon;
            break;
        case 'person':
            Icon = PersonIcon;
            break;
        case 'article':
            Icon = ArticleIcon;
            break;
        case 'altRoute':
            Icon = AltRouteIcon;
            break;
        case 'mobileFriendly':
            Icon = MobileFriendlyIcon;
            break;
        case 'webStories':
            Icon = WebStoriesIcon;
            break;
        case 'settings':
            Icon = SettingsIcon;
            break;
        case 'api':
            Icon = ApiIcon;
            break;
        case 'bugReport':
            Icon = BugReportIcon;
            break;
        case 'storeMallDirectory':
            Icon = StoreMallDirectoryIcon;
            break;
        case 'adb':
            Icon = AdbIcon;
            break;
        case 'speed':
            Icon = SpeedIcon;
            break;
        case 'apple':
            Icon = AppleIcon;
            break;
        case 'accountTree':
            Icon = AccountTreeIcon;
            break;
        case 'assignmentInd':
            Icon = AssignmentIndIcon;
            break;
        case 'verifiedUser':
            Icon = VerifiedUserIcon;
            break;
        case 'diversity1':
            Icon = Diversity1Icon;
            break;
        case 'tipsAndUpdates':
            Icon = TipsAndUpdatesIcon;
            break;

        default:
            Icon = WebIcon
            break;
    }
    return (
        <Icon sx={{ fontSize: 24, ...iconProps.sx }} color={iconProps.color} />
    )
}

const Footer = ({ sx, company, extraPages = [] }: FooterProps) => {
    const isMobile = useIsMobile();
    const lan = useLanguage();
    const today = new Date();
    const companyName = company?.name ?? '';
    const contactData = company?.contactData ?? [];
    const copyright = replacePlaceholders(lan.getTranslation("generic.copyright"), { year: today.getFullYear(), companyName })
    const selectedLanguage = useSelector(
        (state: any) => state?.languages?.selectedLanguage
    ).language as 'es' | 'en';

    return (
        <Grid id='footer' container sx={{ backgroundColor: '#252525' }}>
            <Grid item xs={12}>
                <Grid container sx={{ minHeight: isMobile ? '100px' : '300px', ...sx }}>
                    <Grid item md={3} sx={{ display: 'flex', flexDirection: 'column', py: 2, px: "50px" }}>
                        <BText variant="h5" color={'white'} textAlign="left" marginY={2}>
                            {companyName}
                        </BText>
                        {
                            contactData.map((contact, index) => contact.inFooter && (
                                <BText variant="body1" color={'#909AA8'} key={index} textAlign='left' marginY='2px'>
                                    {contact.data}
                                </BText>
                            ))
                        }
                        <FlexBox style={{ gap: '10px', marginTop: '30px', marginBottom: isMobile ? '0px' : '30px' }}>
                            {
                                contactData.map((contact, index) => contact.isSocialMedia && (
                                    <BLink key={index} to={contact.source}>
                                        <Fab color="primary" aria-label="add" >
                                            {getIconByName(contact.name ?? '')}
                                        </Fab>
                                    </BLink>

                                ))
                            }
                        </FlexBox>
                    </Grid>
                    <Grid item md={3} sx={{ display: extraPages.length > 0 ? 'flex' : 'none', flexDirection: 'column', py: 2, px: "50px", my: isMobile ? 2 : 0 }}>
                        <BText variant="h5" color={'white'} textAlign="left" marginY={2}>
                            {lan.getTranslation("generic.information")}
                        </BText>
                        {
                            extraPages.map((page, index) => (
                                <BLink key={index} to={page.source || '#'}>
                                    <BText variant="body1" color={'#909AA8'} key={index} textAlign='left' marginY='2px'>
                                        {page.name[selectedLanguage]}
                                    </BText>
                                </BLink>
                            ))
                        }

                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Divider sx={{ backgroundColor: '#909AA8' }} />
            </Grid>

            <Grid item xs={12} sx={{ px: isMobile ? 3 : 10, my: 3 }}>

                <BText color={'#909AA8'}>
                    {copyright}
                </BText>
            </Grid>
        </Grid>

    )
}

export default Footer
