import React, { useEffect } from "react";
import { motion, useAnimation, Variants, HTMLMotionProps } from "framer-motion";
import { useTheme } from "@mui/material";

interface HamburgerIconProps extends HTMLMotionProps<"div"> {
    isOpen: boolean;
}

const HamburgerIcon: React.FC<HamburgerIconProps> = ({ isOpen, ...rest }) => {
    const controls = useAnimation();
    const theme = useTheme();

    useEffect(() => {
        if (isOpen) {
            controls.start("animate");
        } else {
            controls.start("initial");
        }
    }, [isOpen, controls]);

    const lineVariants: Variants = {
        initial: { rotate: 0, y: 0 },
        animate: { rotate: isOpen ? 45 : -45, y: isOpen ? 9 : -9 },
    };

    const line2Variants: Variants = {
        initial: { x: 0, opacity: 1 },
        animate: { x: isOpen ? 250 : 0, opacity: isOpen ? 0 : 1 },
    };

    const line3Variants: Variants = {
        initial: { rotate: 0, y: 0 },
        animate: { rotate:  -45, y:  -9 },
    };

    const containerStyles: React.CSSProperties = {
        overflow: 'hidden',
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        cursor: "pointer",
        padding: "2px",
    };
    

    const lineStyles = {
        height: "3px",
        backgroundColor: theme.palette.primary.main,
    };

    const line1Styles = {
        ...lineStyles,
        width: "35px",
        marginBottom: "7px",
    };

    const line2Styles = {
        ...lineStyles,
        width: "25px",
        marginBottom: "7px",
    };

    const line3Styles = {
        ...lineStyles,
        width: "35px",
    };

    return (
        <motion.div style={containerStyles} {...rest}>
            <motion.div style={line1Styles} variants={lineVariants} animate={controls}></motion.div>
            <motion.div style={line2Styles} variants={line2Variants} animate={controls}></motion.div>
            <motion.div style={line3Styles} variants={line3Variants} animate={controls}></motion.div>
        </motion.div>
    );
};

export default HamburgerIcon;
