import React, { CSSProperties } from 'react';
import { Link } from 'react-scroll';
import BButton, { BButtonProps } from './BButton';
import { SxProps } from '@mui/system';
import BLink from '../BLink';

interface LinkableButtonProps extends BButtonProps {
    to: string;
    smooth?: boolean;
    duration?: number;
    children: React.ReactNode;
    sx?: SxProps;
    linkStyle?: CSSProperties;
    linkType?: 'scroll' | 'external'
}

const LinkableButton = ({ to, smooth = true, duration = 250, children, sx, linkStyle, linkType = 'scroll', ...props }: LinkableButtonProps) => {

    const LinkComponent = linkType === 'scroll' ? Link : BLink;
    return (
        <LinkComponent
            to={to}
            smooth={smooth}
            duration={duration}
            style={{ color: 'white', cursor: to ? 'pointer' : 'none', ...linkStyle }}
        >
            <BButton
                color={props.color}
                sx={{ textTransform: 'none', minWidth: '140px', borderRadius: 8, padding: 3, my: 4, ...sx }}
                {...props}
            >
                {children}
            </BButton>
        </LinkComponent>

    );
};

export default LinkableButton;
