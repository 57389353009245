import React from "react";
import { Link as ScrollLink } from "react-scroll";

interface BNavBarLogoProps {
    logoSrc: string,
    height?: number,
    width?: number,
    setHomeIsActive: React.Dispatch<React.SetStateAction<boolean>>;
    [rest: string]: any; // Adjust the type based on expected props
}

const BNavBarLogo: React.FC<BNavBarLogoProps> = ({ logoSrc, setHomeIsActive, height=120, width=120, ...rest }) => {

    const classes = {
        root: {
            cursor: "pointer"
        } as React.CSSProperties
    }

    return (
        
        <ScrollLink
            spy
            smooth
            duration={500}
            offset={-70}
            to="home"
            onSetActive={() => setHomeIsActive(true)}
            onSetInactive={() => setHomeIsActive(false)}
            style={classes.root}
        >
            <img src={logoSrc} alt="logo" height={height} width={width}/>
        </ScrollLink>
    );
};

export default BNavBarLogo;
