import React from 'react'
import { Post } from '../../../libs/types/Post'
import { Grid, GridProps } from '@mui/material'
import BPostCard from '../../landing/blog/BPostCard'
import BText, { BTextProps } from '../../BText'
import { isMobile } from 'react-device-detect'
import { useLanguage } from '../../../hooks/useLanguage'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
type PostsGridProps = {
    posts: Post[],
    gridProps?: GridProps,
    titleProps?: BTextProps,
    shortDescriptionProps?: BTextProps,
}
const PostsGrid = ({ posts = [], gridProps, titleProps, shortDescriptionProps }: PostsGridProps) => {

    const t = useLanguage();
    const noContent = t.getT("landing.blog.noPosts");
    return (
        <Grid container {...gridProps}>
            {
                posts.length > 0 ? posts.map((post: Post, index: number) => (
                    <Grid xs={12} md={6}>
                        <BPostCard
                            post={post}
                            imageOnRight={isMobile ? false : index > 1}
                            titleProps={titleProps}
                            shortDescriptionProps={shortDescriptionProps}
                            gridProps={{
                                sx:{
                                    backgroundColor:'white'
                                }
                            }}
                        />
                    </Grid>
                )) : (
                    <Grid  p={2} gap={1} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                        <BText variant="subtitle1" color={'GrayText'} textAlign={'center'}>
                            {noContent}
                        </BText>
                        <LibraryBooksIcon sx={{ fontSize: 52 }}  color='disabled' />
                    </Grid>
                )
            }
        </Grid>
    )
}

export default PostsGrid