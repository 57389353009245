import { Box, useTheme } from '@mui/material';
import React from 'react'
import MainProviders from './MainProviders';


export type MainLayoutProps = {
    children: React.ReactNode
}
const MainLayout = ({ children }: MainLayoutProps) => {
    const theme = useTheme();
    return (
        <MainProviders>
            <Box style={{ backgroundColor: theme.palette.background.default, width: '100vw' }}>
                {children}
            </Box>
        </MainProviders>

    )
}

export default MainLayout
