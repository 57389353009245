import { Grid, GridProps, Paper } from '@mui/material'
import React from 'react'
import BImage from '../../BImage'
import { Post } from '../../../libs/types/Post'
import BText, { BTextProps } from '../../BText'
import { useSelector } from 'react-redux'
import { truncateString } from '../../../libs/utils/string.utils'
import BButton from '../../buttons/BButton'
import { useLanguage } from '../../../hooks/useLanguage'
import BLink from '../../BLink'
import { useIsMobile } from '../../../hooks/useWindow'

type BPostCardProps = {
    post: Post,
    gridProps?: GridProps,
    titleProps?: BTextProps,
    shortDescriptionProps?: BTextProps,
    imageOnRight?: boolean
}
const BPostCard = ({ post, titleProps, shortDescriptionProps,imageOnRight=false, gridProps }: BPostCardProps) => {
    const { coverImage, title, shortDescription, publicAt, slug, author } = post;
    const lan = useSelector(
        (state: any) => state?.languages?.selectedLanguage
    ).language as 'es' | 'en';
    const t = useLanguage();
    const isMobile= useIsMobile();
    const ctaButtonLabel = t.getTranslation("landing.blog.postCtaButtonLabel");


    return (
        <Grid container width={isMobile?'100%':520} height={'auto'} {...gridProps}>
            <Grid item xs={12} md={6} order={imageOnRight?2:1} >
                <BImage image={coverImage} height={isMobile?200:'100%'} width={'100%'} />
            </Grid>
            <Grid item xs={12} md={6} py={2} px={3} order={imageOnRight?1:2}>
                <BText variant="h6" fontWeight={'bold'} color={'primary'}  {...titleProps}>
                    {title[lan]}
                </BText>
                <BText variant="overline" color={'GrayText'}  >
                    {`${author} • ${publicAt.toLocaleDateString()}`}
                </BText>
                <BText variant="body2" {...shortDescriptionProps}>
                    {truncateString(shortDescription[lan] ?? '', 200)}
                </BText>
                <BLink to={`/blog/${slug}`} >
                    <BButton variant='text'>
                        {ctaButtonLabel}
                    </BButton>
                </BLink>
            </Grid>
        </Grid>
    )
}

export default BPostCard