import { Grid } from '@mui/material'
import { PostSection } from '../../../libs/types/PostSection'
import BText from '../../BText'
import { useSelector } from 'react-redux'
import { useLanguage } from '../../../hooks/useLanguage'
import LinkableButton from '../../buttons/LinkableButton'

type BContentTableProps = {
    postSections: PostSection[]
}

const BContentTable = ({ postSections = [] }: BContentTableProps) => {
    const lan = useSelector(
        (state: any) => state?.languages?.selectedLanguage
    ).language as 'es' | 'en';
    const t = useLanguage();
    return (
        <Grid container xs={12} md={12}>
            <Grid item xs={12} md={12}>
                <BText variant="h6" fontWeight={'bold'}>
                    {t.getTranslation("generic.tableOfContents")}
                </BText>
            </Grid>
            <Grid item xs={12} md={12} display={'flex'} flexDirection={'column'} my={2}>
                {
                    postSections.map((section: PostSection) => (
                        <li style={{ width: '100%' }}>
                            <LinkableButton
                                variant='text'
                                to={section.id}
                                sx={{ m: 0,}}
                            >
                                <BText variant="subtitle1" color={'GrayText'} textAlign={'left'} sx={{width:'100%'}}>
                                    {section.title[lan]}
                                </BText>
                            </LinkableButton>
                        </li>

                    ))
                }
            </Grid>
        </Grid>
    )
}

export default BContentTable