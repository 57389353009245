import BNavBar from '../../navbars/BNavBar'
import { useSelector } from 'react-redux';

type AppNavBarProps = {
  disabledNavBar?: boolean
}
const AppNavBar = ({ disabledNavBar=true }: AppNavBarProps) => {
  const menuOptions = useSelector(
    (state: any) => state?.menu?.navBarOptions
  );
  return (
    <BNavBar
      logoSrc='https://firebasestorage.googleapis.com/v0/b/vierigarcia-lp.appspot.com/o/icons%2Fvg_primary.svg?alt=media&token=45f74942-8704-4500-b9cc-9973711da25f'
      menuOptions={menuOptions}
      disabled={disabledNavBar}
    />
  )
}

export default AppNavBar