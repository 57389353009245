import { ReactNode, useState } from "react";
import { Provider as ReduxProvider } from "react-redux";
import { store } from "../../redux/app.store";
import { MainTheme } from "../../settings/themes/main";
import { ThemeProvider } from "@mui/material";
import LoaderContext from "../../contexts/LoaderContext";

export type MainProvidersProps = {
	children: string | ReactNode;

}

const MainProviders = ({
	children
}: MainProvidersProps) => {

	const [isLoading, setIsLoading] = useState(true);

	return (
		<LoaderContext.Provider value={{ isLoading, setIsLoading }}>
			<ReduxProvider store={store}>
				<ThemeProvider theme={MainTheme}>
					{children}
				</ThemeProvider>
			</ReduxProvider>
		</LoaderContext.Provider>
	);
};

export default MainProviders;
