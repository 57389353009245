

import AppHomeSection from './AppHomeSection';
import { useInitReduxState } from '../../../hooks/useReduxState';
import { populateDatabase } from '../../../data/default';
import AppServicesSection from './AppServicesSection';
import AppTestimonialsSection from './AppTestimonialsSection';
import AppAboutSection from './AppAboutSection';
import AppBlogSection from './AppBlogSection';
import AppNavBar from './AppNavBar';




function LandingPage() {
  useInitReduxState();
  //populateDatabase();
  return (
    <>
      <AppNavBar disabledNavBar={false}/>
      <AppHomeSection />
      <AppServicesSection />
      <AppAboutSection />
      <AppTestimonialsSection />
      <AppBlogSection />
    </>

  );
}

export default LandingPage;
