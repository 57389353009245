import MainLayout, { MainLayoutProps } from '../layouts/MainLayout'
import AppFooter from './landing/AppFooter'
import Loader from '../loaders/Loader'


const AppMainLayout = ({ children }: MainLayoutProps) => {
    return (
        <MainLayout>
            <Loader />
            {children}
            <AppFooter />
        </MainLayout>
    )
}

export default AppMainLayout