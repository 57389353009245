import React, { useEffect, useState } from 'react'
import { Banner } from '../../../libs/types/Banner';
import BannerService from '../../../libs/services/BannerService';
import BannerHomeSection from '../../sections/home/BannerHomeSection';
import BNavBar from '../../navbars/BNavBar';
import { useSelector } from 'react-redux';
import { Service } from '../../../libs/types/Service';
import ServicesTabsTable from '../../sections/services/ServicesTabsTable';
import { useIsMobile } from '../../../hooks/useWindow';
import { Config } from '../../../libs/types/Config';


const AppHomeSection = () => {
    const [banners, setBanners] = useState<Banner[]>([]);
    const bannerService = new BannerService()

    const init = async () => {
        setBanners(await bannerService.find());
    }

    const config: Config = useSelector(
        (state: any) => state?.config?.config
    );
    const services = config ? config?.company.services ?? [] : [];
    useEffect(() => {
        init();
    }, [])



    return (
        <BannerHomeSection
            banner={banners && banners[0]}
            extraCtaComponent={<ServicesTabsTable services={services} sx={{ mt: -5 }} />}
        />
    )
}

export default AppHomeSection
