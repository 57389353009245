import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import BText from '../BText';
import { Box, SxProps } from '@mui/material';
import { Option } from '../../libs/types/Option';
import { arrayToObject } from '../../libs/utils/array.utils';


type BDropDownProps = {
    onChange?: (newOption: Option | undefined) => any;
    value?: any;
    options?: Option[];
    style?: SxProps;
}

export default function BDropDown({ onChange, value, options = [], style }: BDropDownProps) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [selectedOption, setSelectedOption] = React.useState<Option>();
    const open = Boolean(anchorEl);
    const optionsMap = arrayToObject(options, 'value');

    React.useEffect(() => {
        if (value) {
            setSelectedOption(optionsMap[value]);
        }

    }, []);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (item: Option | undefined) => {
        setAnchorEl(null);
        setSelectedOption(item);
        onChange && onChange(item);
    };

    React.useEffect(() => {
        if (selectedOption) {
            setSelectedOption(optionsMap[selectedOption?.value])
        }
    }, [options])


    return (
        <Box>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                endIcon={open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                sx={{
                    width: 10,
                    textTransform: 'none',
                    color: 'black',
                    textAlign: 'left',
                    ...style
                }}
            >
                <BText variant='body1' >
                    {selectedOption?.label}
                </BText>

            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={() => handleClose(selectedOption)}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {options.map((item, index) => (
                    <MenuItem key={index} value={item?.value} onClick={() => handleClose(item)}>
                        {item.label || item?.value}
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
}