
import { db } from '../../../../settings/firebase';

import {
    collection,
    addDoc,
    updateDoc,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
} from "firebase/firestore";
import CoreRepository from '../../../core/CoreRepository.d';
import { Page } from '../../../types/Page';

const collectionName = "pages";


export default class PageFirebaseRepository implements CoreRepository<Page>{

    find = async (filters: any): Promise<Page[]> => {
        const elements: Page[] = [];
        const res = await getDocs(collection(db, collectionName));

        res.forEach((doc: any) => {
            const element: Page = {
                id: doc.id,
                code: doc.data().code,
                name: doc.data().name,
                isMenuOption: doc.data().isMenuOption,
                isExtraPage: doc.data().isExtraPage,
                menuOrder: doc.data().menuOrder,
            }
            elements.push(element);
        });
        return elements;
    }

    create = async (element: Page): Promise<Page> => {
        const doc: any = await addDoc(collection(db, collectionName), element);
        const res: Page = {
            id: doc.id,
            code: doc.data().code,
            name: doc.data().name,
            isMenuOption: doc.data().isMenuOption,
            isExtraPage: doc.data().isExtraPage,
            menuOrder: doc.data().menuOrder,
        }
        return res;
    }

}
