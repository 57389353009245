/**
 * Genera un ID aleatorio compuesto por 9 caracteres alfanuméricos.
 * @returns El ID generado.
 */
export function generateId(length: number): string {
  return Math.random().toString(36).substr(2, length);
}

/**
 * Elimina los espacios en blanco de una cadena de texto y la convierte en formato de título.
 * @param field - La cadena de texto a procesar.
 * @returns La cadena de texto sin espacios en blanco y en formato de título.
 */
export function removeSpacesString(field: string) {
  const previousField: string = field.trim().replace(/\s+/g, ' ').toLowerCase();
  return previousField[0].toUpperCase() + previousField.slice(1);
}

/**
 * Normaliza una cadena de texto, convirtiéndola a mayúsculas y eliminando acentos y caracteres especiales.
 * @param str - La cadena de texto a normalizar.
 * @returns La cadena de texto normalizada.
 */
export const normalizeString = (str: string) => {
  return str
    .toUpperCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^\w\s]/gi, '');
};

/**
 * Realiza una comparación de cadenas de texto sin tener en cuenta mayúsculas, minúsculas, acentos ni caracteres especiales.
 * @param str1 - La primera cadena de texto a comparar.
 * @param str2 - La segunda cadena de texto a comparar.
 * @returns Devuelve true si las cadenas de texto son similares (ignorando mayúsculas, minúsculas, acentos y caracteres especiales), de lo contrario devuelve false.
 */
export function ilike(str1: string, str2: string) {
  const nstr1 = normalizeString(str1);
  const nstr2 = normalizeString(str2);
  return (
    nstr1.toLowerCase().indexOf(nstr2.toLowerCase()) !== -1 ||
    nstr2.toLowerCase().indexOf(nstr1.toLowerCase()) !== -1
  );
}

/**
 * Convierte la primera letra de una palabra en mayúscula.
 * @param word - La palabra a procesar.
 * @returns La palabra con la primera letra en mayúscula.
 */
export function firstLetterUppercase(word: string) {
  const firstLetterUppercase = word[0].toUpperCase();
  return firstLetterUppercase + word.substring(1);
}

/**
 * Convierte la primera letra de una palabra en minúscula.
 * @param word - La palabra a procesar.
 * @returns La palabra con la primera letra en minúscula.
 */
export function firstLetterLowercase(word: string) {
  const firstLetterUppercase = word[0].toLowerCase();
  return firstLetterUppercase + word.substring(1);
}

/**
 * Convierte una palabra en formato PascalCase.
 * @param word - La palabra a procesar.
 * @returns La palabra en formato PascalCase.
 */
export function pascalizeWord(word: string) {
  const firstLetterUpperCasedWord = firstLetterUppercase(word);
  return firstLetterUpperCasedWord
    .trim()
    .split('-')
    .map(singleWord => firstLetterUppercase(singleWord))
    .join('')
    .replace(/[\W\s_-]/g, '');
}

/**
 * Convierte una palabra en formato camelCase.
 * @param word - La palabra a procesar.
 * @returns La palabra en formato camelCase.
 */
export function camelizeWord(word: string) {
  return firstLetterLowercase(pascalizeWord(word));
}

/**
 * Formatea una cadena de texto utilizando un template y los parámetros proporcionados.
 * @param template - El template de la cadena de texto.
 * @param params - Los parámetros utilizados en el template.
 * @returns La cadena de texto formateada.
 */
export function replacePlaceholders(template: string, params: Record<string, any>): string {
  const names = Object.keys(params);
  const vals = Object.values(params);
  return new Function(...names, `return \`${template}\`;`)(...vals);
}


/**
 * Agrega ceros al principio de un número convertido a cadena para alcanzar una longitud específica.
 * @param {number} number - El número a convertir a cadena y al que se agregarán ceros.
 * @param {number} n - Longitud deseada de la cadena resultante, incluyendo el número y los ceros agregados.
 * @returns {string} - La cadena resultante con ceros agregados al principio para alcanzar la longitud especificada.
 */
export function padStart(number: number, n: number): string {
  const str = number.toString();
  const len = str.length;

  const padding = n - len;

  const zeros = "0".repeat(padding);

  return zeros + str;
}

/**
 * Convierte una cadena en un slug name.
 * @param text La cadena de texto que se convertirá en slug.
 * @returns El slug name generado a partir de la cadena.
 */
export function getSlug(text: string): string {
  const trimmedInput = text.trim().toLowerCase();
  const slug = trimmedInput
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "") // Elimina diacríticos
    .replace(/[^a-z0-9]/g, "-")      // Reemplaza caracteres no alfanuméricos por guiones
    .replace(/-{2,}/g, "-");         // Elimina múltiples guiones consecutivos

  return slug;
}

/**
 * Tokeniza una cadena en un arreglo de palabras normalizadas.
 * @param inputString La cadena que se tokenizará.
 * @returns Un arreglo de palabras normalizadas.
 */
export function tokenizeString(inputString: string): string[] {
  // Normalizar la cadena: Convertir a minúsculas y eliminar caracteres especiales
  const normalizedString = inputString.toLowerCase().replace(/[^\w\s]/g, '');

  // Tokenizar la cadena normalizada en un arreglo de palabras separadas por espacios
  const wordsArray = normalizedString.split(/\s+/);

  return wordsArray;
}


/**
 * Trunca un string [inputString] al [maxLength] especificado y agrega '...' al final si se ha truncado.
 * Toma un [inputString] como entrada y un [maxLength] como el límite máximo de caracteres.
 * Si la longitud del [inputString] es menor o igual que [maxLength], devuelve el [inputString] sin cambios.
 * De lo contrario, corta el [inputString] en la posición [maxLength - 3] y agrega '...' al final para indicar que se ha truncado.
 * @param inputString - El string de entrada a truncar.
 * @param maxLength - La longitud máxima de caracteres permitida.
 * @returns El string truncado.
 *
 * Ejemplo:
 * ```typescript
 * const originalString = 'Este es un ejemplo de una cadena larga que queremos truncar';
 * const maxLength = 20;
 *
 * const truncatedString = truncateString(originalString, maxLength);
 * console.log(truncatedString); // Salida: 'Este es un ejemp...'
 * ```
 */
export function truncateString(inputString: string, maxLength: number): string {
  if (inputString.length <= maxLength) {
    return inputString;
  }

  return inputString.substring(0, maxLength - 3) + '...';
}
