import React, { useState } from 'react'
import { Translation } from '../../../libs/types/Translation'
import SectionContainer from '../../containers/SectionContainer'
import BText, { BTextProps } from '../../BText'
import { useIsMobile } from '../../../hooks/useWindow'
import { Grid, IconProps, useTheme } from '@mui/material'
import { useLanguage } from '../../../hooks/useLanguage'
import { useSelector } from 'react-redux'
import { Feature } from '../../../libs/types/Feature'
import DocShapeBox from '../../DocShapeBox'
import MetricsBar from './MetricsBar'
import { Metric } from '../../../libs/types/Metric.d '
import FeaturesGrid from './FeaturesGrid'
import BCtaButton from '../../landing/BCtaButton'

type AboutSectionProps = {
    title?: Translation,
    description?: Translation,
    ctaButtonLabel?: Translation,
    titleProps?: BTextProps,
    descriptionProps?: BTextProps,
    features?: Feature[],
    metrics?: Metric[]
}
const AboutSection = ({ title, description,ctaButtonLabel, features = [], metrics = [], titleProps, descriptionProps }: AboutSectionProps) => {
    const lan = useSelector(
        (state: any) => state?.languages?.selectedLanguage
    ).language as 'es' | 'en';
    const lang = useLanguage();
    const sectionTitle = title ? title[lan] : lang.getT("landing.aboutUs.title");
    const sectionDesc = description ? description[lan] : lang.getT("landing.aboutUs.description");
    const sectionCtaButtonLabel = ctaButtonLabel ? ctaButtonLabel[lan] : lang.getT("landing.aboutUs.ctaButtonLabel");
    const isMobile = useIsMobile();
    const theme = useTheme();


    return (
        <SectionContainer id='aboutUs' height={'auto'} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
            <Grid container py={isMobile ? 15 : 10} width={'auto'} justifyContent={'center'} >
                
                <Grid item xs={12} md={5} px={2} my={4} display={'flex'} flexDirection={'column'} justifyContent={'flex-start'}>
                    <BText variant="h2" sx={{ mb: 4 }} fontWeight={isMobile?'bold':'regular'} {...titleProps}>{sectionTitle}</BText>
                    <BText variant="body1" {...descriptionProps} >{sectionDesc}</BText>
                    <BCtaButton label={sectionCtaButtonLabel}/>
                </Grid>
                <Grid item xs={12} md={4} px={2} display={'flex'} flexDirection={'column'} justifyContent={'center'}
                    sx={{
                        backgroundColor: isMobile ? theme.palette.secondary.main : 'white'
                    }}>

                    <MetricsBar
                        metrics={metrics}
                        labelTextProps={{
                            color: isMobile ? 'white' : 'black',
                            variant: isMobile ? 'h5' : 'h6'
                        }}
                        metricTextProps={{
                            color: isMobile ? 'white' : 'black',
                            variant: isMobile ? 'h3' : 'h4'
                        }}
                        direction='column'

                    />
                </Grid>
                <Grid item xs={12} md={12} px={2}>
                    <FeaturesGrid
                        title={title}
                        description={description}
                        features={features}
                        titleProps={titleProps}
                        descriptionProps={descriptionProps}
                        // sx={{
                        //     display:'flex',
                        //     justifyContent:'center'
                        // }}
                    />
                </Grid>
            </Grid>
        </SectionContainer>
    )
}

export default AboutSection