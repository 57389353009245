import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import './settings/firebase';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import AppAboutUsPage from './components/app/aboutUs';
import AppMainLayout from './components/app/AppMainLayout';
import LandingPage from './components/app/landing';
import BlogPostPage from './components/app/blog';
import AppTermsAndCondsPage from './components/app/termsAndConds';



const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
  }, {
    path: "/aboutUs",
    element: <AppAboutUsPage />,
  }, {
    path: "/blog/:postSlug",
    element: <BlogPostPage />,
  }, {
    path: "/termsAndConds",
    element: <AppTermsAndCondsPage />,
  },
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <AppMainLayout>
      <RouterProvider router={router} />
    </AppMainLayout>
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
