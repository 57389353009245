import React from 'react'
import { Feature } from '../../../libs/types/Feature'
import { IconProps, ListItem, SxProps } from '@mui/material'
import { getIconByName } from '../../footer/Footer'
import BText from '../../BText'
import { useSelector } from 'react-redux'

type FeatureItemProps = {
    feature: Feature,
    iconProps?: IconProps
}
const FeatureItem = ({ feature, iconProps }: FeatureItemProps) => {
    const lan = useSelector(
        (state: any) => state?.languages?.selectedLanguage
    ).language as 'es' | 'en';
    return (
        <ListItem sx={{p:2}}>
            {
                getIconByName(feature.icon ?? '', iconProps)
            }
            <BText ml={2}>
                {feature.name[lan]}
            </BText>
        </ListItem>
    )
}

export default FeatureItem