import React from 'react';
import { motion } from 'framer-motion';
import BText from './BText';
import { useTheme } from '@mui/material';

type BDiscountCircleProps={
    discount: number,
    delay?: number
}

const BDiscountCircle = ({discount, delay}:BDiscountCircleProps) => {
    const theme = useTheme();
    return (
        <motion.div
            initial={{ opacity: 0, scale: 0, rotate: 0 }}
            animate={{ opacity: 1, scale: 1, rotate: 360 }}
            transition={{ duration: 1, ease: 'easeInOut', delay}}
            style={{
                width: 150,
                height: 150,
                borderRadius: '50%',
                backgroundColor: theme.palette.primary.main,
                boxSizing: 'border-box',
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontWeight: 'bold',
                position: 'absolute',
                left:'48%',
                top:'20%'
            }}
        >
            <div style={{
                width: 140,
                height: 140,
                borderRadius: '50%',
                border: '2px dashed #fff',
                display: 'flex',
                flexDirection:'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <BText variant="h3" style={{ transform: 'rotate(-0deg)', textAlign: 'center' }} >{discount}%</BText>
                <BText variant="h4" style={{ transform: 'rotate(-0deg)', textAlign: 'center', margin:0 }} >OFF</BText>
            </div>
        </motion.div>
    );
};

export default BDiscountCircle;
