import React, { useState, useEffect, useContext } from "react";
import { motion, useAnimation, Variants } from "framer-motion";
import { AppBar, SxProps, Toolbar, useTheme } from "@mui/material";
import HamburgerIcon from "../icons/HamburguerIcon";
import { useIsMobile } from "../../hooks/useWindow";
import { hexToRgb } from "../../libs/utils/color.utils";
import BNavBarLogo from "./BNavBarLogo";
import LoaderContext from "../../contexts/LoaderContext";
import BMobileMenu from "./BMobileMenu";
import { MenuOption } from "../../libs/types/MenuOption";
// import Menu from "./Menu";

interface BNavBarProps {
    logoSrc: string;
    menuOptions?: MenuOption[];
    disabled?:boolean;
}


const BNavBar: React.FC<BNavBarProps> = ({ logoSrc, menuOptions, disabled=false }) => {
    const [homeIsActive, setHomeIsActive] = useState<boolean>(true);
    const isMobile: boolean = useIsMobile();
    const { isLoading } = useContext(LoaderContext);
    const controls = useAnimation();
    const theme = useTheme();
    const [scroll, setScroll] = useState<boolean>(false);
    const [mobileNavIsOpen, setMobileNavIsOpen] = useState<boolean>(false);
    const classes = {
        BNavBar: {
            backgroundColor: hexToRgb(theme.palette.background.default, 0),
        },
        toolbar: {
            justifyContent: "space-between",
            alignItems: "center",
            padding: isMobile ? theme.spacing(2, 3) : theme.spacing(0, 6),

        } as SxProps,
    }

    const handleNav = () => setScroll(window.scrollY > 30);
    window.addEventListener("scroll", handleNav);

    const appbarVariants: Variants = {
        initial: { height: isMobile ? 70 : 100, boxShadow: theme.shadows[0] },
        scrolled: { height: isMobile ? 60 : 70, boxShadow: theme.shadows[20], backgroundColor: "rgba(255, 255, 255, 1)" },
    };

    useEffect(() => {
        if (!isLoading) {
            controls.start({
                y: 0,
                transition: {
                    delay: 0.05,
                    type: "spring",
                    stiffness: 260,
                    damping: 20,
                },
            });
        } else {
            controls.start({ y: -100 });
        }
    }, [isLoading, controls]);

    return (
        <motion.div animate={controls}>
            <AppBar position="fixed" elevation={0} sx={classes.BNavBar} component="nav">
                <Toolbar
                    sx={classes.toolbar}
                    component={motion.div}
                    variants={appbarVariants}
                    animate={scroll ? "scrolled" : "initial"}
                    transition={{
                        type: "spring",
                        stiffness: 260,
                        damping: 20,
                    }}
                >
                    <BNavBarLogo logoSrc={logoSrc} setHomeIsActive={setHomeIsActive} />
                    <HamburgerIcon isOpen={mobileNavIsOpen} onClick={() => setMobileNavIsOpen(!mobileNavIsOpen)} />
                </Toolbar>
            </AppBar>
            <BMobileMenu
                open={mobileNavIsOpen}
                menuOptions={menuOptions}
                onClose={() => setMobileNavIsOpen(false)}
                onOpen={() => setMobileNavIsOpen(true)}
                disabled={disabled}
            />
        </motion.div>
    );
};


export default BNavBar;
