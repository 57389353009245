import React, { useState } from 'react'
import BText, { BTextProps } from '../../BText'
import { Box, BoxProps, Grid, GridProps, SxProps, useTheme } from '@mui/material'
import { Translation } from '../../../libs/types/Translation'
import { useSelector } from 'react-redux'
import { useLanguage } from '../../../hooks/useLanguage'
import { useIsMobile } from '../../../hooks/useWindow'
import DocShapeBox from '../../DocShapeBox'
import { Feature } from '../../../libs/types/Feature'

type FeaturesGridProps = {
    title?: Translation,
    description?: Translation,
    titleProps?: BTextProps,
    descriptionProps?: BTextProps,
    features?: Feature[],
    gridProps?: GridProps,
    sx?: SxProps
}
const FeaturesGrid = ({ gridProps, sx, title, description, features = [], titleProps, descriptionProps }: FeaturesGridProps) => {
    const [hoveredItem, setHoveredItem] = useState<null | number>(null);
    const lan = useSelector(
        (state: any) => state?.languages?.selectedLanguage
    ).language as 'es' | 'en';
    const lang = useLanguage();
    const sectionTitle = title ? title[lan] : lang.getT("landing.benefits.title");
    const sectionDesc = description ? description[lan] : lang.getT("landing.benefits.description");
    const isMobile = useIsMobile();
    const theme = useTheme();


    const handleMouseEnter = (index: number) => {
        setHoveredItem(index);
    };
    const handleMouseLeave = () => {
        setHoveredItem(null);
    };

    return (
        <Grid container px={2} py={isMobile ? 15 : 10}>
            <Grid item container md={12} display={'flex'} justifyContent={'center'} gap={2}>
                <Grid item md={7} display={'flex'} flexDirection={'column'} gap={2} >
                    <BText
                        variant={"h2"}
                        fontWeight={isMobile?'bold':'regular'}
                        color='primary'
                        {...titleProps}
                    >
                        {sectionTitle}
                    </BText>
                    <BText
                        variant="body1"
                        {...descriptionProps}
                    >
                        {sectionDesc}
                    </BText>
                </Grid>
            </Grid>
            <Grid item md={12} my={2} display={'flex'} justifyContent={'center'} >
                <Grid container spacing={0} sx={{ display: 'flex', mt: 5 }} {...gridProps}>
                    {
                        features.map((feature: Feature, index: number) => (
                            <Grid item xs={12} md={12/features.length} sx={{ padding: isMobile ? 1 : 3, display:'flex', justifyContent:'center'}}>
                                <DocShapeBox
                                    key={index}
                                    title={feature.name[lan]}
                                    description={feature.description && feature.description[lan]}
                                    icon={feature.icon}
                                    isHovered={index === hoveredItem || index === 0}
                                    onMouseEnter={() => handleMouseEnter(index)}
                                    onMouseLeave={() => handleMouseLeave()}
                                    sx={{
                                        backgroundColor: index === hoveredItem || index === 0 ? theme.palette.primary.main : theme.palette.primary.dark
                                    }}
                                    iconSx={{
                                        color: index === hoveredItem || index === 0 ? theme.palette.primary.dark : theme.palette.primary.main

                                    }}
                                />
                            </Grid>
                        ))
                    }
                </Grid>
            </Grid>
        </Grid>

    )
}

export default FeaturesGrid