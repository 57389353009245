import {
  changeSelectedLanguage,
} from "../redux/slices/language.slice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import { getLanguage } from "../libs/services/repository/local-requests/getLanguage";
import { objectFindNestedKey } from "../libs/utils/json.utils";



export default function useFixedLanguage(
  language ='es',
  langNamespace: string = "common"
) {

  const selectedLanguage = useSelector(
    (state: any) => state?.languages?.selectedLanguage
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (selectedLanguage?.language && Object.keys(selectedLanguage.translations).length <= 0) {
      getLanguage(language || selectedLanguage?.language, langNamespace).then(
        (translations) => {
          dispatch(changeSelectedLanguage({ language, translations }));
        }
      );
    }
    // }
  }, [selectedLanguage, langNamespace,]);

  return selectedLanguage?.translations;
  // return { language: selectedLanguage.fixedLanguage };
}

/***
 * @useSafeFixedLanguage
 * Uses useFixedLanguage internaly but returns an object with getTranslation similar to t function
 * in i18next
 * */
export function useLanguage(
  langName?: string,
  langNamespace: string = "common"
) {
  const translations = useFixedLanguage(langName, langNamespace);
  const getTranslation = (accessKey: string) => {
    return objectFindNestedKey(translations, accessKey);
  };
  return {
    getTranslation,
    getT: getTranslation,
    translations,
    // isLoading,
  } as const;
}
