/**
 * Agrupa los elementos de un array según el valor de una clave específica.
 * Devuelve un array bidimensional que contiene subarrays con elementos consecutivos
 * que tienen el mismo valor en la clave de fusión.
 *
 * @param data - El array de datos a ser agrupados.
 * @param mergeByKey - La clave utilizada para agrupar los elementos.
 * @returns Un array bidimensional que contiene los subarrays fusionados.
 */
export function groupArrayItemsByKey(data: any[], mergeByKey: string) {
  if (!data || data?.length <= 1) {
    return [data];
  }
  const mergedItems: Array<Array<any>> = [[]];
  let mergedIndex = 0;

  data?.reduce((prev: any, current: any, index: number) => {
    if (prev?.[mergeByKey] !== current?.[mergeByKey]) {
      mergedItems[mergedIndex].push(prev);
      mergedIndex++;
      mergedItems[mergedIndex] = [];
      if (index >= data?.length - 1) {
        mergedItems[mergedIndex].push(current);
      }
    } else {
      mergedItems[mergedIndex].push(prev);
      if (index >= data?.length - 1) {
        mergedItems[mergedIndex].push(current);
      }
    }

    return current;
  });
  return mergedItems;
}

/**
 * Filtra un array de objetos por el valor de un campo específico.
 * Devuelve un nuevo array con los elementos que coinciden con el valor especificado.
 *
 * @param array - El array que se desea filtrar.
 * @param field - El campo utilizado para realizar el filtro.
 * @param value - El valor que se desea comparar.
 * @returns Un nuevo array con los elementos filtrados.
 */
export function filterByKey(array: any[], field: string, value: any): any[] {
  return array.filter(item => item[field] === value);
}

/**
 * Filtra un array de objetos por múltiples valores en un campo específico.
 * Devuelve un nuevo array con los elementos que coinciden con al menos uno de los valores especificados.
 *
 * @param array - El array que se desea filtrar.
 * @param field - El campo utilizado para realizar el filtro.
 * @param values - Un array de valores que se desean comparar.
 * @returns Un nuevo array con los elementos filtrados.
 */
export function filterByKeyMultiple(
  array: any[],
  field: string,
  values: any[]
): any[] {
  return array.filter(item => values.includes(item[field]));
}

/**
 * Elimina objetos de un array que no tienen ninguna clave.
 * Devuelve un nuevo array que contiene solo los objetos que tienen al menos una clave.
 *
 * @param arr - El array del cual se eliminarán los objetos sin claves.
 * @returns Un nuevo array que contiene solo los objetos que tienen al menos una clave.
 */
export function removeObjectsWithoutKeys(arr: any) {
  return arr.filter((obj: any) => Object.keys(obj).length !== 0);
}

/**
 * Calcula la suma de los valores de un campo específico en un array de objetos.
 *
 * @param arr - El array de objetos del cual se calculará la suma.
 * @param field - El campo utilizado para obtener los valores a sumar.
 * @returns La suma de los valores del campo especificado.
 */
export function sumByField(arr: any[], field: string) {
  return arr.reduce(
    (accumulator, currentValue) => accumulator + currentValue[field],
    0
  );
}

/**
 * Calcula el promedio de los valores de un campo específico en un array de objetos.
 *
 * @param arr - El array de objetos del cual se calculará el promedio.
 * @param field - El campo utilizado para obtener los valores a promediar.
 * @returns El promedio de los valores del campo especificado.
 */
export function avgByField(arr: any[], field: string) {
  return sumByField(arr, field) / arr.length;
}

/**
 * Convierte un array de objetos en un objeto donde las claves se obtienen a partir de un campo específico.
 *
 * @param array - El array de objetos que se desea convertir en un objeto.
 * @param field - El campo utilizado para obtener las claves del nuevo objeto.
 * @returns Un objeto donde las claves se obtienen a partir del campo especificado.
 */
export function arrayToObject(array: any[], field: string): any {
  return array.reduce((item, current) => {
    item[current[field]] = current;
    return item;
  }, {});
}

/**
 * Fusiona dos arrays en uno nuevo, combinando los elementos por índice.
 * Devuelve un nuevo array que contiene objetos fusionados de los dos arrays.
 * Los objetos se fusionan combinando las propiedades de los objetos con el mismo índice.
 * Ambos arrays deben tener la misma longitud.
 *
 * @param array1 - El primer array a fusionar.
 * @param array2 - El segundo array a fusionar.
 * @returns Un nuevo array que contiene los objetos fusionados de los dos arrays.
 * Si los arrays no tienen la misma longitud, devuelve false.
 */
export function mergeArrays(array1: any[], array2: any[]): any[] | boolean {
  if (array1.length == array2.length) {
    const newArray = [];
    for (let i = 0; i < array1.length; i++) {
      newArray.push({ ...array1[i], ...array2[i] });
    }
    return newArray;
  }
  return false;
}

/**
 * Fusiona dos arrays en uno nuevo, combinando los elementos por campos específicos.
 * Devuelve un nuevo array que contiene objetos fusionados de los dos arrays.
 * Los objetos se fusionan combinando las propiedades de los objetos que coinciden en los campos especificados.
 * Ambos arrays deben tener la misma longitud.
 *
 * @param array1 - El primer array a fusionar.
 * @param array2 - El segundo array a fusionar.
 * @param field - El campo utilizado para la comparación en el primer array.
 * @param field2 - El campo utilizado para la comparación en el segundo array.
 * @returns Un nuevo array que contiene los objetos fusionados de los dos arrays.
 * Si los arrays no tienen la misma longitud, devuelve false.
 */
export function mergeArraysByFields(
  array1: any[],
  array2: any[],
  field = 'componentId',
  field2 = 'id'
): any[] | boolean {
  if (array1.length == array2.length) {
    const newArray = [];
    for (const componentX of array1) {
      const boardTag = array2.find(
        componentY => componentY?.[field] === componentX?.[field2]
      );
      const combinedObject = { ...componentX, ...boardTag };
      newArray.push(combinedObject);
    }
    return newArray;
  }
  return false;
}

/**
 * Ordena un array de objetos por el valor de un campo específico.
 * Devuelve un nuevo array con los objetos ordenados en función del campo especificado.
 *
 * @param arr - El array de objetos que se desea ordenar.
 * @param field - El campo utilizado para ordenar los objetos.
 * @param asc - Indica si el orden es ascendente (true) o descendente (false). Por defecto, es ascendente.
 * @returns Un nuevo array con los objetos ordenados según el campo especificado.
 */
export function sortByField(arr: any[], field: string, asc = true): any[] {
  const sortedArr = arr.slice().sort((a, b) => {
    const valueA = a[field] === undefined ? '' : a[field];
    const valueB = b[field] === undefined ? '' : b[field];

    const compare = valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
    return asc ? compare : -compare;
  });
  return sortedArr;
}


/**
 * Elimina elementos indefinidos y objetos sin claves de un array.
 * Devuelve un nuevo array que contiene solo los elementos definidos y objetos con al menos una clave.
 *
 * @param arr - El array del cual se eliminarán los elementos indefinidos y objetos sin claves.
 * @returns Un nuevo array que contiene solo los elementos definidos y objetos con al menos una clave.
 */
export function removeArrayUndefineds(arr: any[]): any[] {
  const filterArray = arr.filter(
    element => element !== undefined && Object.keys(element).length > 0
  );
  return filterArray;
}

/**
 * Comprueba si todos los elementos de un array están presentes en otro array.
 * Devuelve true si todos los elementos están presentes, de lo contrario, devuelve false.
 *
 * @param arr - El array en el que se buscarán los elementos.
 * @param items - El array de elementos que se buscarán en el otro array.
 * @returns True si todos los elementos están presentes, de lo contrario, false.
 */
export function arrayIncludesItems(arr: any[], items: any[]): boolean {
  const isBelowThreshold = (item: any) => arr.includes(item);
  return items.every(isBelowThreshold);
}

/**
 * Calcula la diferencia entre dos arrays.
 * Devuelve un nuevo array que contiene los elementos que están en arr2 pero no en arr1.
 *
 * @param arr1 - El primer array de elementos.
 * @param arr2 - El segundo array de elementos.
 * @returns Un nuevo array que contiene los elementos que están en arr2 pero no en arr1.
 */
export function arrayDifference(arr1: any[], arr2: any[]) {
  // Filtrar los elementos que están en arr2 pero no en arr1
  const diff = arr2.filter(item => !arr1.includes(item));

  return diff;
}

/**
 * Obtiene el valor extremo (mínimo o máximo) de un campo específico en un array de objetos.
 *
 * @param array - El array de objetos del cual se obtendrá el valor extremo.
 * @param valueType - El tipo de valor extremo a obtener. Puede ser 'MIN' para el mínimo o 'MAX' para el máximo.
 * @param field - El campo utilizado para obtener el valor extremo.
 * @returns El valor extremo del campo especificado.
 */
export function getExtremeValue(array: any, valueType: 'MIN'|'MAX', field: string) {
  if (valueType === 'MIN') {
    return array.reduce(function (previous: any, current: any) {
      return previous[field] < current[field] ? previous : current;
    })[field];
  } else if (valueType === 'MAX') {
    return array.reduce(function (previous: any, current: any) {
      return previous[field] > current[field] ? previous : current;
    })[field];
  }
}

/**
 * Suma valores de un campo que cumplan una condición.
 *
 * @param arr - El array de objetos.
 * @param field - Campo a sumar.
 * @param condition - Función de condición.
 * @returns Suma de valores que cumplen la condición.
 */
export function sumWithCondition(arr: any[], field: string, condition: (item: any) => boolean): number {
  return arr.reduce((accumulator, currentValue) => {
    if (condition(currentValue)) {
      return accumulator + currentValue[field];
    }
    return accumulator;
  }, 0);
}

/**
 * Calcula el promedio de valores de un campo que cumplan una condición.
 *
 * @param arr - El array de objetos.
 * @param field - Campo para calcular el promedio.
 * @param condition - Función de condición.
 * @returns Promedio de valores que cumplen la condición.
 */
export function avgWithCondition(arr: any[], field: string, condition: (item: any) => boolean): number {
  const filteredItems = arr.filter(condition);
  
  if (filteredItems.length === 0) {
    return 0; // Evita la división por cero
  }
  
  const sum = filteredItems.reduce((accumulator, currentValue) => accumulator + currentValue[field], 0);
  return sum / filteredItems.length;
}

/**
 * Divide un arreglo en partes de tamaño específico.
 * @param arr El arreglo que se dividirá en partes.
 * @param chunkSize El tamaño de cada parte.
 * @returns Un arreglo de arreglos, cada uno con 'chunkSize' elementos (excepto posiblemente el último).
 */
export function splitArray<T>(arr: T[], chunkSize: number): T[][] {
  const chunks: T[][] = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
      chunks.push(arr.slice(i, i + chunkSize));
  }
  return chunks;
}

