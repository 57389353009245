// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDTH8wDq_AsvAKW79xNL-CkTGjd7kHCcI4",
  authDomain: "vierigarcia-lp.firebaseapp.com",
  projectId: "vierigarcia-lp",
  storageBucket: "vierigarcia-lp.appspot.com",
  messagingSenderId: "387646558541",
  appId: "1:387646558541:web:631ab9965d6af9a28d6704",
  measurementId: "G-PE8HR6YK9B"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const storage = getStorage(app);