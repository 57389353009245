
import {
  Typography,
  TypographyProps,
} from "@mui/material";

import { FC } from "react";
import { useTheme } from "@mui/material";

export interface BTextProps extends TypographyProps {
  colorVariant?: string;
}


const BText: FC<BTextProps> = ({ colorVariant,  ...props }) => {
  const theme: any = useTheme();
  const selectedColorVariant: any = colorVariant
    ? theme.palette?.[colorVariant]
    : props?.style?.color;

  const color =
    typeof selectedColorVariant === "object"
      ? selectedColorVariant?.main
      : selectedColorVariant;

  return (
    <Typography
      {...props}
      style={{ color, ...props.style }}
      sx={{
        ...props.sx
      }}
    />
  );
};

export default BText;
