
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { MenuOption } from "../../libs/types/MenuOption";
import { sortByField } from "../../libs/utils/array.utils";
import { Page } from "../../libs/types/Page";
import { Config } from "../../libs/types/Config";

type initialStateProps = {
	navBarOptions: MenuOption[],
	selectedOption: MenuOption | undefined,
	footerOptions: MenuOption[],
}

const initialState: initialStateProps = {
	selectedOption: undefined,
	navBarOptions: [],
	footerOptions: []
};

const menuSlice = createSlice({
	name: "menus",
	initialState,
	reducers: {
		setSelectedOption: (state, action: PayloadAction<MenuOption>) => {
			state.selectedOption = action.payload;
			return state;
		},
		setOptions: (state, action: PayloadAction<{ pages: Page[], config: Config }>) => {
			const navBarOptions: MenuOption[] = [];
			const footerOptions: MenuOption[] = [];
			sortByField(action.payload.pages, 'menuOrder', true).forEach((page) => {
				if (action.payload.config.availablePages.includes(page.code)) {
					if (page.isMenuOption) {
						navBarOptions.push({
							code: page.code,
							name: page.name,
							source: `/${page.code}`
						})
					}
					if (page.isExtraPage) {
						footerOptions.push({
							code: page.code,
							name: page.name,
							source: `/${page.code}`
						})
					}

				}
			});
			state.navBarOptions = navBarOptions;
			state.footerOptions= footerOptions;
			state.selectedOption = navBarOptions.length > 0 ? navBarOptions[0] : undefined
			return state;
		},
	},
});

export const {
	setSelectedOption,
	setOptions,
} = menuSlice.actions;
export default menuSlice.reducer;
