import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import AppNavBar from '../landing/AppNavBar';
import { useInitReduxState } from '../../../hooks/useReduxState';
import PostService from '../../../libs/services/PostService';
import { Post } from '../../../libs/types/Post';
import BBlogPost from '../../landing/blog/BBlogPost';
import { Filter } from '../../../libs/types/Filter';
//import { posts } from '../../../data/default';

const BlogPostPage = () => {
    let { postSlug } = useParams();
    useInitReduxState();
    const [post, setPost] = useState<Post | undefined>();
    const postService = new PostService();

    const init = async () => {
        const results: Post[] = await postService.find([
            {
                field: 'slug',
                operator: '==',
                value: postSlug
            } as Filter
        ])
        setPost(results[0])
        //setPost(posts[0])
    }
    useEffect(() => {
        init();
    }, [])

    return (
        <>
            <AppNavBar />
            <BBlogPost post={post} />
        </>
    )
}

export default BlogPostPage