import * as React from "react";
import { SVGMotionProps, motion } from "framer-motion";

const fillColor='#FF9C00';
const container = {
    hidden: {
        opacity: 1,
        scale: 1,
    },
    visible: {
        opacity: 0,
        scale: 0,
        transition: {
            delay: 1.5,
            duration: 0.3,
        },
    },
};

const svg = {
    hidden: {
        opacity: 1,
    },
    visible: {
        opacity: 0.5,
        transition: {
            when: "afterChildren",
            duration: 0.1,
            delay: 3,
            staggerChildren: 0.2,
        },
    },
};

const path = (strokeDashoffset: number) => ({
    hidden: { strokeDashoffset },
    visible: {
        strokeDashoffset: 0,
    },
});

const pathProps = {
    variants: path(200),
    transition: {
        duration: 1,
    },
};

const VieriGarciaLogo: React.FC<SVGMotionProps<SVGSVGElement>> = (props) => {

    return (
        <>
            <motion.div variants={container} initial="hidden" animate="visible">
                <motion.svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px" y="0px"
                    viewBox="0 0 1080 1080"
                    variants={svg}
                    initial="hidden"
                    animate="visible"
                    {...props}
                >
                    <g>
                        <g>
                            <g>
                                <motion.path {...pathProps} fill={fillColor} className="st0" d="M387.5,395.99v20.05c0,6.58-0.48,13.39-1.52,20.05c-0.32,2.17-0.72,4.33-1.12,6.5
					c-3.69,19.17-11.31,36.97-22.7,52.94c-11.07,15.64-25.67,29.11-42.27,38.82c-21.01,12.59-45.32,19.25-70.02,19.25
					c-24.62,0-48.92-6.66-70.1-19.33c-16.44-9.62-31.04-23.1-42.19-38.9c-11.31-15.8-18.93-33.61-22.62-52.86
					c-0.4-2.17-0.8-4.25-1.04-6.42c-1.12-6.66-1.6-13.39-1.6-20.05c0-6.82,0.48-13.47,1.52-20.05
					c9.7-66.41,67.05-117.5,136.03-117.5c24.22,0,48.04,6.34,68.9,18.45l9.95,5.85v54.46h-40.1v-30.64
					c-12.11-5.29-25.35-8.02-38.74-8.02c-46.84,0-86.14,33.29-95.36,77.4c-1.36,6.5-2.09,13.15-2.09,20.05
					c0,6.18,0.64,12.59,1.85,18.85c0.08,0.4,0.16,0.8,0.24,1.2c2.73,13.07,8.02,25.18,15.8,36.09
					c7.94,11.23,18.21,20.77,29.92,27.59c15.08,8.98,32.4,13.47,49.73,13.47c17.32,0,34.57-4.49,49.57-13.39
					c11.79-6.9,22.06-16.44,29.92-27.51c7.86-11.07,13.15-23.18,15.8-36.25H171.11c-0.32-1.12-0.64-2.17-0.88-3.29l-0.16-0.48
					l-0.08-0.56c-0.96-5.21-1.52-10.67-1.52-15.72c0-5.69,0.56-11.15,1.76-16.76c0.24-1.12,0.56-2.17,0.8-3.29H387.5z"/>
                            </g>
                            <g>
                                <motion.path {...pathProps} fill={fillColor} className="st0" d="M65.8,599.15v23.02l184.15,130.25L474.2,593.94v49.09L249.95,801.51L25.7,642.94V521.67l224.25,158.57
					L434.1,549.99v-23.02L249.95,657.14L25.7,498.57V395.99h72.02c-0.96,6.42-1.44,12.99-1.44,20.05c0,6.9,0.56,13.63,1.52,20.05
					h-32v41.71l184.15,130.25L474.2,449.49v121.27L249.95,729.32L65.8,599.15z"/>
                            </g>
                        </g>
                        <g>
                            <motion.path {...pathProps} fill={fillColor} className="st0" d="M611.99,554l43.65-158.27h41.7l-50.48,170.7h-72.18l-50.48-170.7h44.14L611.99,554z" />
                            <motion.path {...pathProps} fill={fillColor} className="st0" d="M705.24,405.48c0-6.34,2.27-11.79,6.83-16.34c4.55-4.55,10-6.83,16.34-6.83s11.79,2.28,16.34,6.83
				c4.55,4.55,6.83,10,6.83,16.34c0,6.18-2.28,11.58-6.83,16.22c-4.55,4.63-10,6.95-16.34,6.95s-11.79-2.32-16.34-6.95
				C707.52,417.07,705.24,411.66,705.24,405.48z M747.92,566.43H708.9V439.62h39.02V566.43z"/>
                            <motion.path {...pathProps} fill={fillColor} className="st0" d="M831.86,437.18c21.95,0,39.42,7.97,52.43,23.9c13.01,15.93,17.4,34.63,13.17,56.09h-91.94
				c2.27,5.69,5.97,10.2,11.1,13.53c5.12,3.33,11.01,5,17.68,5c11.87,0,20.73-4.71,26.58-14.14l31.7,14.63
				c-6.02,9.92-14.15,17.85-24.39,23.78c-10.24,5.94-21.95,8.9-35.12,8.9c-19.35,0-35.57-6.3-48.65-18.9
				c-13.09-12.6-19.63-28.25-19.63-46.94c0-18.69,6.42-34.34,19.26-46.94C796.9,443.49,812.83,437.18,831.86,437.18z M831.86,470.35
				c-5.69,0-10.81,1.59-15.36,4.76c-4.55,3.17-8.05,7.44-10.49,12.8h51.94C853.23,476.2,844.54,470.35,831.86,470.35z"/>
                            <motion.path {...pathProps} fill={fillColor} className="st0" d="M954.27,439.62v31.7c2.11-7.96,4.91-14.59,8.41-19.87c3.5-5.28,7.23-8.9,11.22-10.85
				c3.98-1.95,8.25-3.09,12.8-3.41c4.55-0.32,8.94,0.49,13.17,2.44v36.58c-13.01-5.04-23.86-5.57-32.56-1.59
				c-8.7,3.99-13.05,11.1-13.05,21.34v70.48h-39.02V439.62H954.27z"/>
                            <motion.path {...pathProps} fill={fillColor} className="st0" d="M1007.97,405.48c0-6.34,2.27-11.79,6.83-16.34c4.55-4.55,10-6.83,16.34-6.83c6.34,0,11.79,2.28,16.34,6.83
				c4.55,4.55,6.83,10,6.83,16.34c0,6.18-2.28,11.58-6.83,16.22c-4.55,4.63-10,6.95-16.34,6.95c-6.34,0-11.79-2.32-16.34-6.95
				C1010.24,417.07,1007.97,411.66,1007.97,405.48z M1050.65,566.43h-39.02V439.62h39.02V566.43z"/>
                        </g>
                        <g>
                            <motion.path {...pathProps} fill={fillColor} className="st0" d="M601.61,625.98h45.2c0.58,6.43-0.09,12.45-2.01,18.05c-1.92,5.6-4.72,10.24-8.4,13.93
				c-3.68,3.69-8,6.58-12.95,8.67c-4.95,2.09-10.13,3.14-15.55,3.14c-11.27,0-20.74-3.76-28.4-11.27s-11.49-16.76-11.49-27.75
				c0-10.98,3.83-20.23,11.49-27.75c7.66-7.51,17.13-11.27,28.4-11.27c8.31,0,15.61,2.13,21.89,6.4c6.29,4.27,10.8,9.47,13.55,15.61
				l-14.31,5.53c-1.88-3.9-4.68-7.1-8.4-9.59c-3.72-2.49-7.97-3.74-12.74-3.74c-6.72,0-12.38,2.38-16.96,7.16
				c-4.59,4.77-6.88,10.66-6.88,17.67c0,7.01,2.29,12.9,6.88,17.67c4.59,4.77,10.24,7.16,16.96,7.16c5.71,0,10.53-1.55,14.47-4.66
				c3.94-3.11,6.38-6.83,7.32-11.16h-28.07V625.98z"/>
                            <motion.path {...pathProps} fill={fillColor} className="st0" d="M679.78,611.68c7.08,0,12.83,1.84,17.23,5.53c4.41,3.69,6.61,9.07,6.61,16.15v35.33h-14.63v-6.83
				c-4.41,5.27-10.3,7.91-17.67,7.91c-5.27,0-9.63-1.46-13.06-4.39c-3.43-2.93-5.15-6.81-5.15-11.65c0-10.48,7.19-16.47,21.57-17.99
				l9.11-0.87c3.47-0.21,5.2-1.7,5.2-4.44c0-2.02-0.85-3.65-2.55-4.88c-1.7-1.23-3.88-1.84-6.56-1.84c-2.89,0-5.26,0.74-7.1,2.22
				c-1.84,1.48-3.02,3.56-3.52,6.23l-14.52-3.25c0.8-4.99,3.43-9.11,7.91-12.36C667.13,613.3,672.84,611.68,679.78,611.68z
				 M674.9,658.17c3.83,0,7.13-1.3,9.92-3.9c2.78-2.6,4.17-5.6,4.17-8.99v-1.19c-0.72,0.58-2.38,1.05-4.99,1.41l-9.43,1.08
				c-2.1,0.29-3.76,0.96-4.99,2.01c-1.23,1.05-1.84,2.37-1.84,3.96c0,1.66,0.65,3.02,1.95,4.06
				C671,657.65,672.74,658.17,674.9,658.17z"/>
                            <motion.path {...pathProps} fill={fillColor} className="st0" d="M729.72,612.76v7.26c2.46-3.39,5.56-5.8,9.32-7.21c3.76-1.41,7.62-1.5,11.6-0.27v13.87
				c-5.85-2.02-10.8-1.93-14.85,0.27c-4.05,2.2-6.07,5.76-6.07,10.68v31.32h-14.63v-55.93H729.72z"/>
                            <motion.path {...pathProps} fill={fillColor} className="st0" d="M763.76,661.43c-5.71-5.56-8.56-12.47-8.56-20.7s2.85-15.14,8.56-20.7c5.71-5.56,12.83-8.35,21.35-8.35
				c6,0,11.38,1.56,16.15,4.66c4.77,3.11,8.35,7.48,10.73,13.11l-13.44,4.66c-1.23-2.89-3.04-5.11-5.42-6.67
				c-2.38-1.55-5.06-2.33-8.02-2.33c-4.19,0-7.7,1.5-10.52,4.5c-2.82,3-4.23,6.7-4.23,11.11c0,4.48,1.41,8.22,4.23,11.22
				c2.82,3,6.32,4.5,10.52,4.5c2.96,0,5.64-0.8,8.02-2.39c2.38-1.59,4.19-3.83,5.42-6.72l13.44,4.66
				c-2.38,5.64-5.96,10.01-10.73,13.11c-4.77,3.11-10.15,4.66-16.15,4.66C776.59,669.77,769.47,666.99,763.76,661.43z"/>
                            <motion.path {...pathProps} fill={fillColor} className="st0" d="M818.64,597.26c0-2.46,0.87-4.53,2.6-6.23c1.74-1.7,3.83-2.55,6.29-2.55c2.38,0,4.44,0.85,6.18,2.55
				c1.73,1.7,2.6,3.78,2.6,6.23c0,2.38-0.87,4.45-2.6,6.18c-1.74,1.73-3.79,2.6-6.18,2.6c-2.46,0-4.55-0.87-6.29-2.6
				C819.51,601.71,818.64,599.64,818.64,597.26z M834.79,668.69h-14.63v-55.93h14.63V668.69z"/>
                            <motion.path {...pathProps} fill={fillColor} className="st0" d="M870.3,611.68c7.08,0,12.83,1.84,17.23,5.53c4.41,3.69,6.61,9.07,6.61,16.15v35.33h-14.63v-6.83
				c-4.41,5.27-10.3,7.91-17.67,7.91c-5.27,0-9.63-1.46-13.06-4.39c-3.43-2.93-5.15-6.81-5.15-11.65c0-10.48,7.19-16.47,21.57-17.99
				l9.11-0.87c3.47-0.21,5.2-1.7,5.2-4.44c0-2.02-0.85-3.65-2.55-4.88c-1.7-1.23-3.88-1.84-6.56-1.84c-2.89,0-5.26,0.74-7.1,2.22
				c-1.84,1.48-3.02,3.56-3.52,6.23l-14.52-3.25c0.8-4.99,3.43-9.11,7.91-12.36C857.65,613.3,863.36,611.68,870.3,611.68z
				 M865.42,658.17c3.83,0,7.13-1.3,9.92-3.9c2.78-2.6,4.17-5.6,4.17-8.99v-1.19c-0.72,0.58-2.38,1.05-4.99,1.41l-9.43,1.08
				c-2.1,0.29-3.76,0.96-4.99,2.01c-1.23,1.05-1.84,2.37-1.84,3.96c0,1.66,0.65,3.02,1.95,4.06
				C861.52,657.65,863.25,658.17,865.42,658.17z"/>
                        </g>
                    </g>

                </motion.svg>
            </motion.div>
        </>
    );
};

export default VieriGarciaLogo;

