import { ContentBlock, ContentSource } from '../../../libs/types/PostSection'
import { Grid } from '@mui/material'
import BText from '../../BText'
import { useSelector } from 'react-redux'
import { Translation } from '../../../libs/types/Translation'
import BImage from '../../BImage'
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import ReactPlayer from 'react-player'

type BContentBlockProps = {
    contentBlock: ContentBlock
}

const BContentBlock = ({ contentBlock }: BContentBlockProps) => {
    const lan = useSelector(
        (state: any) => state?.languages?.selectedLanguage
    ).language as 'es' | 'en';
    const { title, contentSources } = contentBlock;
    return (
        <Grid container xs={12} md={12} gap={2}>
            <Grid item md={12}>
                <BText variant="h5">
                    {title && title[lan]}
                </BText>
            </Grid>
            <Grid item container xs={12} md={12} my={1} >
                {
                    contentSources.map((contentSource: ContentSource, index: number) => {
                        if (contentSource.type === 'text') {
                            const text = contentSource.source as Translation;
                            return (
                                <Grid   my={1} item xs={12} md={12 / contentSources.length} order={contentSource.position === 'left' ? 1 : 2}>
                                    <BText variant="body1" component={'div'} dangerouslySetInnerHTML={{ __html: (text && text[lan]) ?? '' }} />
                                </Grid>
                            )
                        } else if (contentSource.type === 'image') {
                            const image = contentSource.source as string;
                            return (
                                <Grid  my={1} item xs={12} md={12 / contentSources.length} order={contentSource.position === 'left' ? 1 : 2} p={1}>
                                    <BImage height={340} imageSize="cover" width={'100%'} image={image}  />
                                </Grid>
                            )
                        }
                        else if (contentSource.type === 'audio') {
                            const audio = contentSource.source as string;
                            return (
                                <Grid  my={1} item xs={12} md={12 / contentSources.length} order={contentSource.position === 'left' ? 1 : 2}>
                                    <AudioPlayer
                                        autoPlay
                                        src={audio}
                                    />
                                </Grid>
                            )
                        }
                        else if (contentSource.type === 'video') {
                            const video = contentSource.source as string;
                            return (
                                <Grid  my={1} item xs={12} md={12 / contentSources.length} order={contentSource.position === 'left' ? 1 : 2}>
                                    <ReactPlayer
                                        width={'100%'}
                                        url={video}
                                    />
                                </Grid>
                            )
                        }
                        return (<></>)
                    })
                }
            </Grid>

        </Grid>
    )
}

export default BContentBlock