
import { db } from '../../../../settings/firebase';

import {
    collection,
    addDoc,
    updateDoc,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
} from "firebase/firestore";
import CoreRepository from '../../../core/CoreRepository.d';
import { Banner } from '../../../types/Banner';

const collectionName = "banners";


export default class BannerFirebaseRepository implements CoreRepository<Banner>{

    find = async (filters: any): Promise<Banner[]> => {
        const elements: Banner[] = [];
        const res = await getDocs(collection(db, collectionName));
        res.forEach((doc: any) => {
            const element: Banner = {
                id: doc.id,
                description: doc.data().description,
                title: doc.data().title,
                tags: doc.data().tags,
                discount: doc.data().discount,
                ctaButtonLabel: doc.data().ctaButtonLabel,
                backgroundImage: doc.data().backgroundImage,
            }
            elements.push(element);
        });
        return elements;
    }

    create = async (element: Banner): Promise<Banner> => {
        const doc: any = await addDoc(collection(db, collectionName), element);
        const res: Banner = {
            id: doc.id,
            description: doc.data().description,
            title: doc.data().title,
            tags: doc.data().tags,
            discount: doc.data().discount,
            ctaButtonLabel: doc.data().ctaButtonLabel,
            backgroundImage: doc.data().backgroundImage,
        }
        return res;
    }

}
