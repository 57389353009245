import { Button, ButtonProps, Tooltip } from "@mui/material";
import React, { CSSProperties, FC, MouseEvent } from "react";


export interface BButtonProps extends ButtonProps {
	children?: React.ReactNode | any;
	style?: CSSProperties;
	onClick?: (e: MouseEvent<HTMLButtonElement>) => any;
	// color?: colorVariants;
	upperCase?: boolean;
}

const BButton: FC<BButtonProps> = ({
	children,
	onClick,
	style,
	color = "primary",
	title,
	variant = "contained",
	upperCase,
	...props
}) => {
	if (title)
		return (
			<Tooltip title={title}>
				<Button
					onClick={onClick}
					style={{ ...style }}
					color={color}
					variant={variant}
					{...props}
				>
					{children}
				</Button>
			</Tooltip>
		);

	return (
		<Button
			onClick={onClick}
			style={{ ...styles.buttonStyles,...style,  }}
			color={color}
			variant={variant}
			{...props}
			sx={{ ...props.sx, textTransform:upperCase ? "uppercase" : "none" }}
		>
			{children}
		</Button>
	);
};

const styles = {
	buttonStyles: {
		borderRadius: "100px",
		padding: "5px",
	},
};

export default BButton;
