import TestimonialsCarouselSection from '../../sections/testimonials/TestimonialsCarouselSection'
import { useIsMobile } from '../../../hooks/useWindow'
import { useSelector } from 'react-redux'
import { Config } from '../../../libs/types/Config'

const AppTestimonialsSection = () => {
    const isMobile = useIsMobile();
    const config: Config = useSelector(
        (state: any) => state?.config?.config
    );
    const customers = config ? config?.company.customers ?? [] : [];
    return (
        <TestimonialsCarouselSection
            customers={customers}
            titleProps={{
                color: 'secondary',
                variant: isMobile ? 'h3' : 'h2'
            }}
        />
    )
}

export default AppTestimonialsSection