import { Link } from "@mui/material";
import { FC, HTMLAttributes } from "react";



interface BLinkProps extends HTMLAttributes<HTMLAnchorElement> {
	to: string;
}

const BLink: FC<BLinkProps> = ({ to, ...props }) => {
	return (
		<Link
			href={to}
			{...props}
		>
			{props.children}
		</Link>
	);
};

export default BLink;
