import React from 'react'
import SectionContainer from '../../containers/SectionContainer'
import { Grid } from '@mui/material'
import BText, { BTextProps } from '../../BText'
import { Translation } from '../../../libs/types/Translation'
import { useLanguage } from '../../../hooks/useLanguage'
import { useSelector } from 'react-redux'
import { useIsMobile } from '../../../hooks/useWindow'
import { Post } from '../../../libs/types/Post'
import PostsGrid from './PostsGrid'

type PostsGridSectionProps = {
    title?: Translation,
    description?: Translation,
    posts: Post[],
    titleProps?: BTextProps,
    descriptionProps?: BTextProps,
}

const PostsGridSection = ({ title, description, titleProps, descriptionProps, posts }: PostsGridSectionProps) => {
    const lan = useSelector(
        (state: any) => state?.languages?.selectedLanguage
    ).language as 'es' | 'en';
    const lang = useLanguage();
    const sectionTitle = title ? title[lan] : lang.getT("landing.blog.title");
    const sectionDesc = description ? description[lan] : lang.getT("landing.blog.description");
    const isMobile = useIsMobile();
    return (
        <SectionContainer id='blog' height={'auto'}>
            <Grid container px={2} py={isMobile ? 15 : 10}>
                <Grid item container md={12} display={'flex'} justifyContent={'center'} gap={2}>
                    <Grid item md={7} display={'flex'} flexDirection={'column'} gap={2} >
                        <BText
                            variant={"h2"}
                            fontWeight={isMobile ? 'bold' : 'regular'}
                            color='primary'
                            {...titleProps}
                        >
                            {sectionTitle}
                        </BText>
                        <BText
                            variant="body1"
                            {...descriptionProps}
                        >
                            {sectionDesc}
                        </BText>
                    </Grid>
                </Grid>
                <Grid item md={12} my={6} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                    <PostsGrid
                        posts={posts}
                        gridProps={{
                            width:isMobile?'100%': 520*2,
                            justifyContent:'center',
                            alignItems:'center'
                        }}
                        titleProps={{
                            color:'secondary'
                        }}
                    />
                </Grid>
            </Grid>
        </SectionContainer>
    )
}

export default PostsGridSection