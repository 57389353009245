import React, { useEffect, useState } from 'react';
import { Divider, List, Paper, SxProps, useTheme } from '@mui/material';
import { motion, useAnimation } from 'framer-motion';
import { Feature } from '../../../libs/types/Feature';
import FeatureItem from './FeatureItem';

type FeaturesBoxProps = {
    features: Feature[];
    sx?: SxProps;
};

const FeaturesBox = ({ features = [], sx }: FeaturesBoxProps) => {
    const [scrollY, setScrollY] = useState(0);
    const controls = useAnimation();
    const theme = useTheme();

    useEffect(() => {
        const handleScroll = () => {
            setScrollY(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        controls.start({
            y: Math.sin(scrollY / 100) * 5, // Ajusta el movimiento aquí, sin es para un movimiento sinusoide
            transition: { duration: 0.5 },
        });
    }, [scrollY, controls]);

    return (
        <motion.div animate={controls}>
            <List component={Paper} elevation={5} sx={{ minWidth: '220px', maxWidth:'300px', px: 3, py:4, ...sx }}>
                {features.map((feature: Feature, index: number) => (
                    <React.Fragment key={index}>
                        <FeatureItem feature={feature} iconProps={{
                            color:'primary',
                            sx:{
                                color:'primary'
                            }
                        }}/>
                        {index !== features.length - 1 && <Divider />}
                    </React.Fragment>
                ))}
            </List>
        </motion.div>
    );
};

export default FeaturesBox;
