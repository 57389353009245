
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Config } from "../../libs/types/Config";

type initialStateProps = {
	config: Config|undefined,
}

const initialState: initialStateProps = {
	config: undefined
};

const configSlice = createSlice({
	name: "config",
	initialState,
	reducers: {
		setConfig: (state, action: PayloadAction<Config>) => {
			state.config = action.payload;
			return state;
		},
	},
});

export const {
	setConfig
} = configSlice.actions;
export default configSlice.reducer;
