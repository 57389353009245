import { useLanguage } from "./useLanguage";
import { replacePlaceholders } from "../libs/utils/string.utils";

export const useGetDefaultCtaSource = () => {
    const t = useLanguage();
    let defaultTo = t.getTranslation("landing.ctaWhatsapp");
    const defaultMessageTemplate = t.getTranslation("landing.ctaMessageTemplate");
    const defaultMessage = replacePlaceholders(defaultMessageTemplate, {});
    defaultTo = replacePlaceholders(defaultTo, { phone: '51933812390', message: defaultMessage });
    return defaultTo;
}
