
import CoreCrudService from "../core/CoreCrudService.d";
import CoreRepository from "../core/CoreRepository.d";
import { Page } from "../types/Page";
import PageFirebaseRepository from "./repository/firebase/PageRepository";

export default class PageService implements CoreCrudService<Page>{
    
    repository: CoreRepository<Page> = new PageFirebaseRepository();

    find = (filters?: any): Promise<Page[]> => {
        if (this.repository.find) {
            return this.repository.find(filters);
        } else {
            return Promise.reject(new Error('find method is undefined.'));
        }
    }

    create = (element: Page): Promise<Page> => {
        return this.repository.create(element);
    }


}
