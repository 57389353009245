import { Grid } from "@mui/material"
import { Post } from "../../../libs/types/Post"
import SectionContainer from "../../containers/SectionContainer"
import BText from "../../BText"
import { useSelector } from "react-redux"
import BPostAuthor from "./BPostAuthor"
import { useIsMobile } from "../../../hooks/useWindow"
import { ContentBlock, PostSection } from "../../../libs/types/PostSection"
import BContentBlock from "./BContentBlock"
import BContentTable from "./BContentTable"
import { Helmet } from  'react-helmet'

type BBlogPostProps = {
    post?: Post
}

const BBlogPost = ({ post }: BBlogPostProps) => {
    const lan = useSelector(
        (state: any) => state?.languages?.selectedLanguage
    ).language as 'es' | 'en';
    const isMobile = useIsMobile();

    if (post) {
        const { title, content, shortDescription } = post;
        const { sections, introduction } = content;
        return (
            <div>
                <Helmet>
                    <title>{title[lan]}</title>
                    <meta name="description" content={shortDescription[lan]} />
                </Helmet>
                <SectionContainer height={'auto'}>
                    <Grid container mt={12} px={isMobile ? 3 : 30}>
                        <Grid item my={2} md={12}>
                            <BText variant="h3" color='secondary'>
                                {title[lan]}
                            </BText>
                            <BPostAuthor post={post} />
                        </Grid>
                        <Grid item md={12}>
                            <BContentBlock
                                contentBlock={introduction}
                            />
                        </Grid>
                        <Grid item my={2} md={12} >
                            <BContentTable postSections={sections} />
                        </Grid>
                        {
                            sections.map((section: PostSection, index: number) => (
                                <Grid item container md={12} key={index} id={section.id} pt={10} my={2}>
                                    <Grid item md={12}>
                                        <BText variant="h4" color='secondary' >
                                            {section.title[lan]}
                                        </BText>
                                    </Grid>
                                    {
                                        section.contentBlocks.map((contentBlock: ContentBlock, index: number) => (
                                            <BContentBlock
                                                contentBlock={contentBlock}
                                                key={index}
                                            />
                                        ))
                                    }
                                </Grid>
                            ))
                        }

                    </Grid>
                </SectionContainer>
            </div>

        )
    }
    return (<></>)

}

export default BBlogPost