import { Box, SxProps, useTheme, } from '@mui/material'
import { useIsMobile } from '../hooks/useWindow';
import BText from './BText';
import { getIconByName } from './footer/Footer';

type DocShapeBoxProps = {
    isHovered: boolean;
    title?: string;
    description?: string;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    icon?: string;
    sx?: SxProps;
    iconSx?: SxProps
}
const DocShapeBox = ({ isHovered, title, description, icon = '', onMouseEnter, onMouseLeave, sx, iconSx }: DocShapeBoxProps) => {
    const isMobile = useIsMobile();
    const theme = useTheme();
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', padding: 5, paddingY:3 ,textAlign: 'left', clipPath: 'polygon(0% 0%, 80% 0%, 100% 20%, 100% 100%, 0% 100%)', backgroundColor: theme.palette.primary.dark, width: '200px', gap: 1, ...sx }} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            {getIconByName(icon, { sx: { fontSize: 42, ...iconSx }, color: 'primary' })}
            <BText variant="h5" fontSize={'bold'} color={isHovered ? "white" : "#233E62"}>{title}</BText>
            <BText variant="body2" color={isHovered ? "white" : "#8A8A8A"} >{description}</BText>
        </Box>

    )
}

export default DocShapeBox