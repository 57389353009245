import React from "react";

interface LoaderContextProps {
    isLoading: boolean;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const LoaderContext = React.createContext<LoaderContextProps>({
    isLoading: true,
    setIsLoading: () => {},
});

export default LoaderContext;
