
import { Box } from '@mui/material'
import MovingArrowForwardIcon from '../icons/MovingArrowForwardIcon'
import BText from '../BText'
import BLink from '../BLink'
import { useGetDefaultCtaSource } from '../../hooks/useGetDefaultCtaSource'

type BCtaButtonProps = {
    to?: string;
    label?: string;
}
const BCtaButton = ({ to, label }: BCtaButtonProps) => {
  
    const defaultTo = useGetDefaultCtaSource();

    return (
        <BLink to={to || defaultTo} style={{ textDecoration: 'none' }}>
            <Box display={'flex'} p={2} alignItems={'center'}>
                <Box sx={{ m: 1 }}>
                    <MovingArrowForwardIcon color='secondary' />
                </Box>
                <BText variant='h6' color='secondary' fontWeight='bold' textAlign={'left'} marginInline={2}>
                    {label}
                </BText>
            </Box>
        </BLink>
    )
}

export default BCtaButton