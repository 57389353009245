import React from 'react'
import { useSelector } from 'react-redux';
import { useIsMobile } from '../../hooks/useWindow';
import SectionContainer from '../containers/SectionContainer';
import { Grid } from '@mui/material';
import BText from '../BText';
import BContentBlock from './blog/BContentBlock';
import BContentTable from './blog/BContentTable';
import { ContentBlock, PostSection } from '../../libs/types/PostSection';
import { PostContent } from '../../libs/types/PostContent';
import { useLanguage } from '../../hooks/useLanguage';

type BTermsAndCondsProps = {
    termsAndConds?: PostContent
}

const BTermsAndConds = ({ termsAndConds }: BTermsAndCondsProps) => {
    const lan = useSelector(
        (state: any) => state?.languages?.selectedLanguage
    ).language as 'es' | 'en';
    const isMobile = useIsMobile();
    const t = useLanguage();
    if (termsAndConds) {
        const { sections, introduction } = termsAndConds;
        return (
            <SectionContainer height={'auto'}>
                <Grid container mt={12} px={isMobile ? 3 : 30}>
                    <Grid item my={2} md={12}>
                        <BText variant="h3" color='secondary'>
                            {t.getTranslation("generic.termsAndConds")}
                        </BText>
                    </Grid>
                    <Grid item md={12}>
                        <BContentBlock
                            contentBlock={introduction}
                        />
                    </Grid>
                    <Grid item my={2} md={12} >
                        <BContentTable postSections={sections} />
                    </Grid>
                    {
                        sections.map((section: PostSection, index: number) => (
                            <Grid item container md={12} key={index} id={section.id} pt={10} my={2}>
                                <Grid item md={12}>
                                    <BText variant="h4" color='secondary' mb={2}>
                                        {section.title[lan]}
                                    </BText>
                                </Grid>
                                {
                                    section.contentBlocks.map((contentBlock: ContentBlock, index: number) => (
                                        <BContentBlock
                                            contentBlock={contentBlock}
                                            key={index}
                                        />
                                    ))
                                }
                            </Grid>
                        ))
                    }

                </Grid>
            </SectionContainer>
        )
    }


    return (<></>)
}

export default BTermsAndConds