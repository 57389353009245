
import CoreCrudService from "../core/CoreCrudService.d";
import CoreRepository from "../core/CoreRepository.d";
import { Post } from "../types/Post";
import PostFirebaseRepository from "./repository/firebase/PostRepository";

export default class PostService implements CoreCrudService<Post>{
    
    repository: CoreRepository<Post> = new PostFirebaseRepository();

    find = (filters?: any): Promise<Post[]> => {
        if (this.repository.find) {
            return this.repository.find(filters);
        } else {
            return Promise.reject(new Error('Find method is undefined'));
        }
    }

    create = (element: Post): Promise<Post> => {
        return this.repository.create(element);
    }

    findOne=(elementId: string) : Promise<Post> =>{
        if (this.repository.findOne) {
            return this.repository.findOne(elementId);
        } else {
            return Promise.reject(new Error('findOne method is undefined.'));
        }
    };


}
