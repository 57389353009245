import React from "react";
import { Grid,  } from "@mui/material";
import SectionContainer from "../../containers/SectionContainer";
import BText, { BTextProps } from "../../BText";
import TestimonialCarousel from "./TestimonialCarousel";
import { Translation } from "../../../libs/types/Translation";
import { useSelector } from "react-redux";
import { Customer } from "../../../libs/types/Customer";
import { useLanguage } from "../../../hooks/useLanguage";
import { useIsMobile } from "../../../hooks/useWindow";

type TestimonialsCarouselSectionProps = {
    title?: Translation,
    description?: Translation,
    customers: Customer[],
    titleProps?: BTextProps,
    descriptionProps?: BTextProps,
}
const TestimonialsCarouselSection = ({ title, description,titleProps,descriptionProps, customers }: TestimonialsCarouselSectionProps) => {
    const lan = useSelector(
        (state: any) => state?.languages?.selectedLanguage
    ).tuage as 'es' | 'en';
    const t = useLanguage();
    const sectionTitle = title ? title[lan] : t.getT("landing.testimonials.title");
    const sectionDesc = description ? description[lan] : t.getT("landing.testimonials.description");
    const isMobile= useIsMobile();

    return (
        <SectionContainer id="testimonials" height={'auto'}>
            <Grid container px={2} py={isMobile?15:10}>
                <Grid item container md={12} display={'flex'} justifyContent={'center'} gap={2}>
                    <Grid item md={7} display={'flex'}  flexDirection={'column'} gap={2} >
                        <BText
                            variant={"h2"}
                            fontWeight={isMobile?'bold':'regular'}
                            color='primary'
                            {...titleProps}
                        >
                            {sectionTitle}
                        </BText>
                        <BText
                            variant="body1"
                            {...descriptionProps}
                        >
                            {sectionDesc}
                        </BText>
                    </Grid>
                </Grid>
                <Grid item md={12} my={2}>
                    <TestimonialCarousel customers={customers} />
                </Grid>
            </Grid>
        </SectionContainer>
    );
};


export default TestimonialsCarouselSection;
