import React, { useContext } from "react";
import { MotionStyle, motion } from "framer-motion";
import LoaderContext from "../../contexts/LoaderContext";

interface LoaderContainerProps {
  children: React.ReactNode;
}

const LoaderContainer: React.FC<LoaderContainerProps> = ({ children }) => {
  const { setIsLoading } = useContext(LoaderContext);
  //const theme = useTheme();

  const classes ={
    container: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#130748",
      zIndex: 10000,
    } as MotionStyle,
    content: {
      margin: "0 auto",
    },
  };

  return (
    <motion.div
      initial={{
        y: 0,
      }}
      animate={{
        y: "-100vh",
      }}
      transition={{
        delay: 2,
        duration: 0.3,
      }}
      onAnimationComplete={() => {
        setIsLoading(false);
      }}
      style={classes.container}
    >
      <div style={classes.content}>{children}</div>
    </motion.div>
  );
};



export default LoaderContainer;
