import React from 'react'
import { Box, SxProps } from '@mui/material';


type CarouselItemProps = {
    children?: React.ReactNode;
    image: string;
    width: number | string;
    height?: number | string;
    outsideContainerSx?: SxProps,
    insideContainerSx?: SxProps,
    itemOpacity?: string
}

const CarouselItem = ({ image, width, height, children, outsideContainerSx, insideContainerSx, itemOpacity = 'rgb(0,0,0,0)' }: CarouselItemProps) => {
    return (
        <Box sx={{
            width: width,
            height: height,
            backgroundImage: `url(${image})`,
            backgroundSize: 'cover',
            ...outsideContainerSx
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center', backgroundColor: itemOpacity, width: '100%', height: '100%',
                ...insideContainerSx
            }}>
                {children}
            </Box>
        </Box>
    )
}


export default CarouselItem