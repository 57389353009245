import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
	selectedLanguage: {
		language: "es",
		translations: {} as any,
	},
};

const languageSlice = createSlice({
	name: "languages",
	initialState,
	reducers: {
		changeSelectedLanguage: (state, action: PayloadAction<any>) => {
			state.selectedLanguage.language = action.payload.language;
			state.selectedLanguage.translations = action.payload.translations;
			return state;
		}
	},
});

export const {
	changeSelectedLanguage
} = languageSlice.actions;
export default languageSlice.reducer;
