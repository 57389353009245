import { useEffect, useState, useRef, CSSProperties } from 'react';
import BText, { BTextProps } from '../BText';
import { Divider, Grid, useTheme } from '@mui/material';
import { Metric } from '../../libs/types/Metric.d ';
import { useSelector } from 'react-redux';

type BMetricProps = {
    metric: Metric,
    incrementSpeed?: number;
    metricTextProps?: BTextProps;
    labelTextProps?: BTextProps;
    style?:CSSProperties
};

const BMetric = ({ metric, incrementSpeed=.5, metricTextProps, labelTextProps , style}: BMetricProps) => {
    const [metricValue, setMetricValue] = useState(0);
    const componentRef = useRef<HTMLDivElement>(null);
    const [startCounting, setStartCounting] = useState(false);
    const theme = useTheme();
    const lan = useSelector(
        (state: any) => state?.languages?.selectedLanguage
    ).language as 'es' | 'en';

    useEffect(() => {
        const handleScroll = () => {
            if (
                componentRef.current &&
                window.innerHeight + window.scrollY >= componentRef.current.offsetTop
            ) {
                setStartCounting(true);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        if (startCounting && metricValue < metric.value) {
            const timer = setTimeout(() => {
                setMetricValue(metricValue + 1);
            }, incrementSpeed);
            return () => clearTimeout(timer);
        }
    }, [startCounting, metricValue, metric.value, incrementSpeed]);

    return (
        <div ref={componentRef} style={{width:'100%', ...style}}>
            <Grid display={'flex'} flexDirection={'column'} justifyContent={'center'} gap={1}>
                <BText variant='h4' {...metricTextProps} >
                    {metricValue}
                </BText>
                <Divider sx={{ width: '35px', bgcolor: theme.palette.primary.main, borderWidth: '1px' }} />
                <BText variant='overline' {...labelTextProps}>
                    {metric.label[lan]}
                </BText>
            </Grid>
        </div>
    );
};

export default BMetric;
