import AppAboutSection from '../landing/AppAboutSection'
import AppTestimonialsSection from '../landing/AppTestimonialsSection'
import { useInitReduxState } from '../../../hooks/useReduxState';
import AppNavBar from '../landing/AppNavBar';


const AppAboutUsPage = () => {
    useInitReduxState();

    return (
        <>
            <AppNavBar />
            <AppAboutSection />
            <AppTestimonialsSection />
        </>
    )
}

export default AppAboutUsPage