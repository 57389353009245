import { Divider, Drawer, List, ListItem, ListItemButton, ListItemText, useTheme } from "@mui/material";
import React from "react";
import { Link } from "react-scroll";
import { MenuOption } from "../../libs/types/MenuOption";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedOption } from "../../redux/slices/menu.slice";
import BLanguageDropDown from "../BLanguageDropDown";

interface BMobileMenuProps {
    open: boolean;
    onClose: () => void;
    onOpen: () => void;
    menuTitle?: string;
    menuOptions?: MenuOption[];
    disabled?:boolean;
}

const BMobileMenu: React.FC<BMobileMenuProps> = ({ menuTitle, menuOptions = [], open, onClose, onOpen, disabled=false }) => {
    const theme = useTheme();
    const classes = {
        drawer: {
            backgroundColor: theme.palette.background.default,
        },
        list: {
            width: 250,
        },
        fullList: {
            width: "auto",
            marginTop: theme.spacing(4),
        },
        listItem: {
            display: "flex",
            justifyContent: "center",
            padding: theme.spacing(2, 0),
            "&:hover": {
                backgroundColor: "rgb(80,80,80)",
            },
            fontSize: theme.typography.h6.fontSize
        },
        btnContainer: {
            display: "flex",
            justifyContent: "center",
            marginTop: theme.spacing(1),
        },
        active: {
            backgroundColor: theme.palette.secondary
        },
    }
    const dispatch = useDispatch();
    const selectedLanguage = useSelector(
        (state: any) => state?.languages?.selectedLanguage
    ).language as 'es' | 'en';
    const selectedOption = useSelector(
        (state: any) => state?.menu?.selectedOption
    );
    const handleOptionSelection = (option: MenuOption) => {
        dispatch(setSelectedOption(option));
    }

    return (
        <Drawer anchor="left" open={open} onClose={onClose} >
            <div style={classes.list} role="presentation">
                <List style={classes.fullList}>
                    {!disabled && menuOptions.map((option: MenuOption, index: number) => (
                        <ListItem key={index} disablePadding >
                            <Link
                                to={`${option.code}`}
                                smooth={true}
                                duration={250}
                                style={{ 'width': '100%' }}
                            >
                                <ListItemButton
                                    sx={{ textAlign: 'center' }}
                                    selected={selectedOption?.code == option.code}
                                    onClick={() => handleOptionSelection(option)}>
                                    <ListItemText color='black' primary={option.name[selectedLanguage]} />
                                </ListItemButton>
                            </Link></ListItem>
                    ))
                    }
                    <Divider/>
                    <ListItem sx={{ display: 'flex', justifyContent:'center'}} >
                        <BLanguageDropDown/>
                    </ListItem>
                </List>
            </div>
        </Drawer>
    );
};


export default BMobileMenu;
