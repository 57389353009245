import { Grid, SxProps } from '@mui/material'
import React, { useState } from 'react'
import { Service } from '../../../libs/types/Service'
import BText from '../../BText'
import { useSelector } from 'react-redux'
import LinkableButton from '../../buttons/LinkableButton'
import TabButton from './TabButton'
import { useIsMobile } from '../../../hooks/useWindow'

type ServicesTabTableProps = {
  services: Service[],
  sx?: SxProps
}

const ServicesTabsTable = ({ services = [], sx }: ServicesTabTableProps) => {

  const selectedLanguage = useSelector(
    (state: any) => state?.languages?.selectedLanguage
  ).language as 'es' | 'en';
  const isMobile = useIsMobile();


  return (
    <Grid container display={'flex'} flexDirection={'row'} sx={{ gap: 0, ...sx }}>
      {
        services.map((service: Service, index: number) => (
          <Grid item xs={4} md={4}>
            <TabButton
              to={service.code}
              color='inherit'
              style={{ borderRadius: 0, border: '1px solid  white' }}
              containerStyle={{height:'auto'}}
              buttonSx={{ width:'100%', height:isMobile?'60px':'auto' }}
            >
              <BText fontWeight={isMobile?'bold':'regular'}>{service.title[selectedLanguage]}</BText>
            </TabButton>
          </Grid>
        ))
      }
    </Grid>
  )
}

export default ServicesTabsTable
