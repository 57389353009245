import { Box, SxProps, useTheme } from '@mui/material';
import React from 'react'
import { motion } from 'framer-motion';

type SectionContainerProps = {
    id?: string;
    height?: number | string;
    width?: number | string;
    children: React.ReactNode;
    style?: SxProps
}

export const SectionContainer = ({ id, height = '100vh', width = '100vw', style, children }: SectionContainerProps) => {
    const theme = useTheme();
    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.0 }}
        >
            <Box id={id} sx={{ height, width, backgroundColor: theme.palette.background.default, ...style }}>
                {children}
            </Box>
        </motion.div>

    )
}

export default SectionContainer;