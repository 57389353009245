import { CSSProperties, HTMLAttributes, forwardRef } from "react";
import { Box } from '@mui/material'

export interface FlexBoxProps extends HTMLAttributes<HTMLDivElement> {
	direction?: "row" | "row-reverse" | "column" | "column-reverse";
	align?: "center" | "flex-start" | "flex-end";
	wrap?: "wrap" | "nowrap" | "wrap-reverse";
	justify?:
	| "space-evenly"
	| "space-between"
	| "space-around"
	| "flex-start"
	| "flex-end"
	| "center";
	style?: CSSProperties
}

const FlexBox = forwardRef<HTMLDivElement, FlexBoxProps>(({
	direction = "row",
	align = "center",
	wrap,
	justify ,
	style,
	...props
}, ref) => {
	return (
		<Box
			style={{
				display: "flex",
				flexDirection: direction,
				alignItems: align,
				flexWrap: wrap,
				justifyContent: justify,
				...style
			}}
			ref={ref}
			{...props}
		>
			{props.children}
		</Box>

	);
});

FlexBox.displayName = "FlexBox";

export default FlexBox;
