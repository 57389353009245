import { Grid } from "@mui/material"
import { Post } from "../../../libs/types/Post"
import BText from "../../BText"

type BPostAuthorProps = {
  post: Post
}

const BPostAuthor = ({ post }: BPostAuthorProps) => {
  const { author, publicAt } = post;
  return (
    <Grid container my={2}>
      <BText variant="overline" color={'GrayText'}  >
        {`${author} • ${publicAt.toLocaleDateString()}`}
      </BText>
    </Grid>
  )
}

export default BPostAuthor