import React from "react";
import { ArrowForward as ArrowForwardIcon } from "@mui/icons-material";
import { motion } from "framer-motion";
import { IconProps } from "@mui/material";

interface MovingArrowForwardIconProps extends IconProps {
    colorVariant?: string;
}

const MovingArrowForwardIcon: React.FC<MovingArrowForwardIconProps> = ({
    fontSize,
    sx,
    color,
}) => {
    const iconVariants = {
        initial: { x: 0 },
        animate: {
            x: [0, 20, 0], // Aquí se define la animación de ida y vuelta
            transition: { duration: 2, ease: "linear", repeat: Infinity },
        },
    };

    return (
        <motion.div
            whileHover={{ scale: 1.3 }}
            whileTap={{ scale: 0.8 }}
            style={{ display: "inline-block" }}
            variants={iconVariants}
            initial="initial"
            animate="animate"
        >
            <ArrowForwardIcon fontSize={fontSize} sx={sx} color={color} />
        </motion.div>
    );
};

export default MovingArrowForwardIcon;
