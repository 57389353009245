
export const isDev = true;
export const isClientSide = typeof window !== "undefined";
export const appConfig = {
	isClientSide,
	API_URL: isDev ? "http://localhost:3000/api/" : "",
	NUMBER_DISPLAY_SETTINGS: {
		style: "decimal",
		maximumFractionDigits: 2,
		minimumFractionDigits: 2,
	} as Intl.NumberFormatOptions,
	NUMBER_LANG: "en-US" as Intl.LocalesArgument,
	FALLBACK_LANG: "es",
};
