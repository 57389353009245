


import React, { ReactNode } from 'react'
import BannerCarouselItem from './BannerCarouselItem'
import SectionContainer from '../../containers/SectionContainer';
import { SxProps } from '@mui/material';
import { useIsMobile } from '../../../hooks/useWindow';
import { Banner } from '../../../libs/types/Banner';

type BannerHomeSectionProps = {
    banner?: Banner,
    sectionProps?: SxProps,
    bannerProps?: SxProps,
    extraCtaComponent?: ReactNode
}


const BannerHomeSection = ({ banner, bannerProps, sectionProps, extraCtaComponent }: BannerHomeSectionProps) => {

    const isMobile = useIsMobile();
    return (
        <SectionContainer id='home'  width={'100%'} style={{ ...sectionProps }}>
            {
                banner && <BannerCarouselItem
                    key={banner?.id}
                    banner={banner}
                    titleProps={{ color: 'white', variant: isMobile ? 'h3' : 'h2' }}
                    descriptionProps={{
                        color: 'white',
                        variant: isMobile ? 'h6' : 'h5'
                    }}
                    subtitleProps={{ color: 'white', display: 'none' }}
                    bannerProps={{
                        px: isMobile ? 3 : 20,
                        justifyContent : 'start',
                        gap:isMobile ?2:1,
                        height:'auto',
                        width:isMobile ?'100%':'550px',
                    }}
                    outsideContainerSx={{
                        ...bannerProps,
                        height:'auto',
                        flexDirection:'column',
                        justifyContent:'start',
                        backgroundColor:'black'
                    }}
                    insideContainerSx={{
                        backgroundColor:'rgb(0,0,0,.6)',
                        minHeight:isMobile ?'650px':'100%',
                        pt:3
                    }}
                    extraCtaComponent={extraCtaComponent}
                />
            }
        </SectionContainer>
    )
}

export default BannerHomeSection