import { useSelector } from "react-redux";
import { useInitReduxState } from "../../../hooks/useReduxState";
import BTermsAndConds from "../../landing/BTermsAndConds"
import AppNavBar from "../landing/AppNavBar"
import { Config } from "../../../libs/types/Config";


const AppTermsAndCondsPage = () => {
    useInitReduxState();
    const config: Config = useSelector(
        (state: any) => state?.config?.config
    );
    const termsAndCond = config ? config?.company?.termsAndCond : undefined;
    return (
        <>
            <AppNavBar />
            <BTermsAndConds termsAndConds={termsAndCond} />
        </>
    )
}

export default AppTermsAndCondsPage