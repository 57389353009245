import { useState } from 'react';
import BDropDown from './inputs/BDropDown';
import { useDispatch, useSelector } from 'react-redux';

import { getLanguage } from '../libs/services/repository/local-requests/getLanguage';
import { SxProps } from '@mui/material';
import { changeSelectedLanguage } from '../redux/slices/language.slice';

type BLanguageDropDownProps = {
    style?: SxProps
};

type Translations = {
    [key: string]: Array<{ label: string; value: string }>;
};

const optionTranslations: Translations = {
    'es': [
        {
            'label': 'Español',
            'value': 'es'
        }, {
            'label': 'Ingles',
            'value': 'en'
        }
    ],
    'en': [
        {
            'label': 'Spanish',
            'value': 'es'
        }, {
            'label': 'English',
            'value': 'en'
        }
    ]
}

const BLanguageDropDown = ({ style }: BLanguageDropDownProps) => {
    const dispatch = useDispatch();
    const selectedLanguage = useSelector(
        (state: any) => state?.languages?.selectedLanguage
    );
    const defaultLan = selectedLanguage.language;
    const [value, setValue] = useState(defaultLan);
    const [options, setOptions] = useState(optionTranslations[defaultLan || 'es'])

    const changeLanguage = (option: any) => {
        const lan = option.value;
        setOptions(optionTranslations[lan]);
        setValue(lan);
        getLanguage(lan, "common").then(
            (translations) => {
                dispatch(changeSelectedLanguage({ language: lan, translations }));
            }
        );
    }



    return (
        <BDropDown
            style={{ width: '100px', marginBlock: "0px", ...style }}
            value={value}
            options={options}
            onChange={changeLanguage}
        />
    )
}

export default BLanguageDropDown;
