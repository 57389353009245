import { useDispatch } from "react-redux";
import ConfigService from "../libs/services/ConfigService";
import PageService from "../libs/services/PageService";
import { setOptions } from "../redux/slices/menu.slice";
import { setConfig } from "../redux/slices/config.slice";

export const useInitReduxState = async () => {
    const dispatch = useDispatch();

    //Init config
    const clientKey =  'DIILqzVNSSHTXfAz5qIb';//process.env.NEXT_CLIENT_KEY;
    const configService = new ConfigService();

    const config:any = await configService.findOne(clientKey || '');
    dispatch(setConfig(config));

    // Init Menu Options
    const pagesService = new PageService();
    const pages = await pagesService.find();
    dispatch(setOptions({ pages, config }));

}
