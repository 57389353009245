import { Grid, SxProps } from '@mui/material'
import { Metric } from '../../../libs/types/Metric.d '
import BMetric from '../../landing/BMetric'
import BText, { BTextProps } from '../../BText';

type MetricsBarProps = {
  metrics: Metric[],
  sx?: SxProps,
  metricTextProps?: BTextProps;
  labelTextProps?: BTextProps;
  direction?: 'row' | 'column'

}
const MetricsBar = ({ metrics, sx, metricTextProps, direction = 'row', labelTextProps }: MetricsBarProps) => {
  return (
    <Grid container sx={{ my: 2, ...sx }}>
      <Grid item md={12} >

      </Grid>
      <Grid item container md={12} >
        {
          metrics.map((metric: Metric) => (
            <Grid item xs={12} md={direction === 'row' ? 12 / metrics.length : 12} display={'flex'} justifyContent={'center'} alignItems={'center'} py={2}>
              <BMetric metric={metric} style={{ width: '250px' }} metricTextProps={metricTextProps} labelTextProps={labelTextProps} />
            </Grid>
          ))
        }
      </Grid>
    </Grid>
  )
}

export default MetricsBar