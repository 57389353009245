import React, { useEffect, useState } from 'react'
import { Post } from '../../../libs/types/Post'
import PostsGridSection from '../../sections/blog/PostsGridSection'
import { useIsMobile } from '../../../hooks/useWindow';
import PostService from '../../../libs/services/PostService';

const AppBlogSection = () => {
    const [posts, setPosts] = useState<Post[]>([]);
    const postService = new PostService()
    const isMobile = useIsMobile();

    const init = async () => {
        setPosts(await postService.find());
    }

    useEffect(() => {
        init();
    }, [])

    return (
        <PostsGridSection
            posts={posts.slice(0, 6)}
            titleProps={{
                color: 'secondary',
                variant: isMobile ? 'h3' : 'h2'
            }}
        />
    )
}

export default AppBlogSection